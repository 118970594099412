import { Helmet } from 'react-helmet-async';

import { WaitlistView } from 'src/sections/waitlist/view';

// ----------------------------------------------------------------------

export default function WaitlistPage() {
  return (
    <>
      <Helmet>
        <title>projectfit.ai | Finally take back your time!</title>
      </Helmet>

      <WaitlistView />
    </>
  );
}
