import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { alpha, Button, useTheme, Typography } from '@mui/material';

import matchLabel from 'src/utils/match-label';

import { matchThemeColor, getMatchLabelColor } from 'src/components/match-level/match-level';

// ----------------------------------------------------------------------

export default function GroupInfoUserOpinion({ item, shown, upsertOpinion }) {
  const theme = useTheme();

  const opinionLevel = item?.userDetails?.opinion?.level ?? undefined;
  const originalMatchLevel = item?.userDetails?.matching?.result?.level ?? undefined;
  const originalMatchColor = getMatchLabelColor(originalMatchLevel, theme);
  const originalMatchLabel = matchLabel(originalMatchLevel, false);

  if (!shown) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ pt: 2, pb: 2, px: 2, backgroundColor: theme.palette.grey[100] }}
    >
      {/* Robert thinks, this project is a ... */}
      {typeof originalMatchLevel !== 'undefined' && (
        <Typography variant="body2">
          Robert thinks this project is a{' '}
          <Typography
            color={originalMatchColor}
            component="span"
            variant="body2"
            sx={{ fontWeight: 'bold' }}
          >
            {originalMatchLabel}
          </Typography>{' '}
          for your profile. How would you rate it?
        </Typography>
      )}

      {/* No Robert match, so ask the user about his opinion */}
      {!originalMatchLevel && (
        <Typography variant="body2">
          How good do you think this project matches your profile?
        </Typography>
      )}

      {/* Buttons for all match levels */}
      <Stack direction="row" spacing={1} sx={{ width: 1, flexWrap: 'wrap' }}>
        {[0, 33, 66, 100].map((level) => (
          <Button
            key={level}
            data-level={level}
            variant={opinionLevel === level ? 'contained' : 'soft'}
            color={matchThemeColor[level]}
            onClick={() => {
              upsertOpinion(level);
            }}
            sx={{
              color: opinionLevel === level ? 'common.white' : 'inherit',
              ...(level === 100
                ? {
                    backgroundColor: alpha(
                      theme.palette.primary.main,
                      opinionLevel === level ? 1 : 0.08
                    ),
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        opinionLevel === level ? 1 : 0.24
                      ),
                    },
                  }
                : {}),
            }}
          >
            {matchLabel(level, level > 0)}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}

GroupInfoUserOpinion.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  shown: PropTypes.bool,
  upsertOpinion: PropTypes.func,
};
