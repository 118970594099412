import PropTypes from 'prop-types';

import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  Stack,
  Button,
  Tooltip,
  useTheme,
  Typography,
  AccordionSummary,
  CircularProgress,
} from '@mui/material';

import { fToNow } from 'src/utils/format-time';
import platformLabel from 'src/utils/platform-label';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetterHeader({ letterCount, posting, item }) {
  const theme = useTheme();

  return (
    <AccordionSummary
      id={`${item._id}-header`}
      expandIcon={letterCount === 1 ? null : <GridExpandMoreIcon />}
      sx={{ backgroundColor: theme.palette.grey[100], px: 2 }}
    >
      {['INITIALIZED', 'PROCESSING'].includes(item.llmStatus) && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Stack>
      )}

      {['FAILED'].includes(item.llmStatus) && (
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          There has been an error
        </Typography>
      )}

      {['FINISHED'].includes(item.llmStatus) && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: 1, flexShrink: 0 }}
        >
          {/* platform icon */}
          <Tooltip
            title={`This project has been found on ${platformLabel(posting.platform)}`}
            arrow
            placement="top"
          >
            <img
              alt={platformLabel(posting.platform)}
              src={`/assets/icons/platforms/${posting.platform}.png`}
              height="16"
              width="16"
              style={{ filter: 'grayscale(100%)' }}
            />
          </Tooltip>

          {/* title and metadata */}
          <Stack direction="column" spacing={0} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {fToNow(item.createdAt)} for
              {posting.details.authorCompany && ` ${posting.details.authorCompany} at`}
              {posting.platform ? ` ${platformLabel(posting.platform)}` : ''}
            </Typography>
          </Stack>

          {/* external link (only visible on MD) */}
          <Button
            href={posting.sourceLink}
            variant="text"
            sx={{ flexShrink: 0, display: { xs: 'none', md: 'flex' } }}
            target="_blank"
            startIcon={<Iconify icon="eva:external-link-fill" width={16} />}
            onClick={(event) => event.stopPropagation()}
          >
            Open Platform
          </Button>
        </Stack>
      )}
    </AccordionSummary>
  );
}

GroupApplicationCoverLetterHeader.propTypes = {
  letterCount: PropTypes.number,
  item: PropTypes.shape({
    _id: PropTypes.string,
    projectId: PropTypes.string,
    llmStatus: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
