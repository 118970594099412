import Lottie from 'react-lottie';
import { useTheme } from '@emotion/react';

import Stack from '@mui/material/Stack';
import { Link, Button } from '@mui/material';
import { Box, Container } from '@mui/system';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { bgGradient } from 'src/theme/css';
import { HEADER } from 'src/layouts/config-layout';
import animationData from 'src/assets/lotties/robert.json';

import Iconify from 'src/components/iconify';
import RoboHeadline from 'src/components/robo-headline';

// ----------------------------------------------------------------------

export default function RobertHero() {
  const theme = useTheme();

  const renderDescription = (
    <Stack
      alignItems="left"
      justifyContent="center"
      sx={{
        height: 1,
        maxWidth: 640,
        mt: { xs: 2, md: 0 },
      }}
    >
      <Box sx={{ mb: 2, display: { xs: 'none', sm: 'block' } }}>
        <RoboHeadline variant="h3" sx={{ width: 'auto', display: 'inline-block' }}>
          Hi, I&apos;m Robert – nice to meet you!
        </RoboHeadline>
      </Box>

      <Typography variant="body1" sx={{ my: 1 }}>
        As the AI behind projectfit.ai, my job is to help you find the best freelance projects. I
        love crawling around the web, searching for new and exciting projects, and figuring out
        which ones are the perfect match for you.
      </Typography>

      <Typography variant="body1" sx={{ my: 1 }}>
        When I&apos;m not busy matching you with awesome opportunities, I enjoy playing video games
        – being online all the time has its perks!
      </Typography>

      <Typography variant="body1" sx={{ my: 1 }}>
        Feel free to write me a message at{' '}
        <Link href="mailto:robert@projectfit.ai">robert@projectfit.ai</Link>. I&apos;m always
        looking to improve and make your freelancing journey even better.
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="solar:letter-bold" />}
          href="mailto:robert@projectfit.ai"
        >
          Send me a message
        </Button>
      </Box>
    </Stack>
  );

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.24,
          ...bgGradient({
            direction: '180deg',
            startColor: alpha(theme.palette.grey[900], 0),
            endColor: alpha(theme.palette.grey[900], 0.1),
          }),
        }}
      />

      <Grid
        container
        columnSpacing={{ xs: 0, sm: 5, md: 10 }}
        sx={{
          minHeight: { xs: `calc(100vh - ${HEADER.H_MOBILE}px)`, md: '100vh' },
          py: { xs: 10, md: 0 },
          overflow: 'hidden',
        }}
      >
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{
            flexShrink: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction="row-reverse"
            alignItems="end"
            justifyContent="center"
            sx={{
              '@media(max-width: 450px)': {
                flexDirection: 'column-reverse',
                alignItems: 'center',
              },
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
                width: 1,
                minWidth: 200,
                maxWidth: {
                  xs: 200,
                  sm: 480,
                },
                aspectRatio: '500/500',
                '@media(max-width: 450px)': {
                  mt: 5,
                },
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width="100%"
              />
            </Box>

            <Typography
              variant="h3"
              sx={{
                minWidth: '200px',
                display: { sm: 'none' },
              }}
            >
              Hi, I&apos;m Robert – nice to meet you!
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} sm={8} md={8} lg={8}>
          {renderDescription}
        </Grid>
      </Grid>
    </Container>
  );
}
