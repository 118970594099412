import PropTypes from 'prop-types';

import { Box, useTheme, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function RoboHeadline({ children, sx, ...other }) {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        sx={{
          paddingRight: '4px',
          borderRight: `.15em solid ${theme.palette.primary.main}`,
          animation: 'blink-caret .75s step-end infinite',
          ...sx,
        }}
        color={theme.palette.primary.main}
        {...other}
      >
        {children}
      </Typography>
    </Box>
  );
}

RoboHeadline.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
