import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from 'src/auth/hooks';

import { LoadingScreen } from 'src/components/loading-screen';

export default function AuthUserAware({ children }) {
  const { user: authUser, authenticated, loading } = useAuthContext();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!authenticated) {
    return <Navigate replace to="/" />;
  }

  if (!authUser?.sub) {
    return <LoadingScreen />;
  }

  return children;
}

AuthUserAware.propTypes = {
  children: PropTypes.node.isRequired,
};
