/* eslint-disable no-nested-ternary */
const allowedAddresstypes = [
  'place',
  'building',
  'house_number',
  'road',
  'city',
  'town',
  'village',
  'postcode',
];

function formatAddress(address) {
  if (!address) return '';

  // city has a special treatment
  const city = address.city || address.town || address.village || address.municipality || '';

  return [
    address.road
      ? `${address.road}${address.house_number ? ` ${address.house_number}` : ''}`
      : undefined,
    `${address.postcode ? `${address.postcode}, ` : ''}${city || ''}`,
    address.country || undefined,
  ]
    .filter((v) => !!v)
    .join(', ');
}

export const formatOptions = (result) => {
  const formatted = (result || [])
    .filter((v) => allowedAddresstypes.includes(v.addresstype))
    .map((v) => ({
      ...v,
      label: formatAddress(v.address),
    }));

  return formatted.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i).slice(0, 5);
};
