import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import { Box, useTheme, Typography } from '@mui/material';

import { factCols } from 'src/pages/dashboard/management/board/item/facts-list';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupInfoPostingFacts({ posting }) {
  const theme = useTheme();
  const facts = useMemo(
    () => factCols(posting.details, posting.distanceToUser),
    [posting.details, posting.distanceToUser]
  );

  return (
    <Stack spacing={1} direction="column">
      {facts.map((fact) => (
        <Box key={fact.key}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon={fact.icon} width={16} height={16} color={theme.palette.grey[600]} />

            <Typography
              variant="caption"
              sx={{
                minWidth: 70,
                color: theme.palette.grey[600],
                lineHeight: 1,
              }}
            >
              {fact.title}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                color: theme.palette.grey[800],
                fontWeight: '600',
                lineHeight: 1,
              }}
            >
              {fact.value}
            </Typography>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}

GroupInfoPostingFacts.propTypes = {
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    distanceToUser: PropTypes.number,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
