import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Tooltip, IconButton, Typography } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function SampleCoverLettersRow({ userSub, coverLetter, onEdit, onDelete }) {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const deleteConfirm = useBoolean(false);
  const deleteProgress = useBoolean(false);

  const handleDeleteItem = async (id) => {
    deleteProgress.onTrue();

    try {
      await api.delete(`/v1/users/${userSub}/sample-cover-letters/${id}`);
      onDelete();
    } catch (e) {
      enqueueSnackbar('Could not delete cover letter!', { variant: 'error' });
    } finally {
      deleteProgress.onFalse();
      deleteConfirm.onFalse();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ display: 'flex', width: 1 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="caption" title={coverLetter.title}>
            <LinesEllipsisLoose text={`${coverLetter.title}`} maxLine="1" lineHeight="20" />
          </Typography>
        </Box>

        <Tooltip arrow title="Edit the cover letter">
          <IconButton size="small" onClick={onEdit}>
            <Iconify icon="solar:pen-bold" width={16} height={16} />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Delete this cover letter">
          <IconButton size="small" onClick={() => deleteConfirm.onTrue()}>
            <Iconify icon="solar:trash-bin-trash-bold" width={16} height={16} />
          </IconButton>
        </Tooltip>
      </Stack>

      <ConfirmDialog
        open={deleteConfirm.value}
        onClose={deleteConfirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete the cover letter "${coverLetter.title}"?`}
        action={
          <LoadingButton
            loading={deleteProgress.value}
            variant="contained"
            color="error"
            onClick={() => handleDeleteItem(coverLetter._id)}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}

SampleCoverLettersRow.propTypes = {
  userSub: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  coverLetter: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};
