import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { textGradient } from 'src/theme/css';

// ----------------------------------------------------------------------
const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(`300deg, #00A76F 0%, #007867 50%, #00A76F 100%`),
  padding: 0,
  margin: 0,
  lineHeight: 1.5,
  fontWeight: 700,
  letterSpacing: 0.5,
  textAlign: 'center',
  backgroundSize: '400%',
  fontSize: `2rem`,
  fontFamily: theme.typography.fontSecondaryFamily,
}));

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ size = 'medium', disabledLink = false, includeName = false, sx, ...other }, ref) => {
    const { pathname } = useLocation();

    const sizeMapping = {
      small: 40,
      medium: 64,
      large: 88,
    };

    const remMapping = {
      small: 1.5,
      medium: 1.5,
      large: 2,
    };

    if (!Object.keys(sizeMapping).includes(size)) {
      throw new Error(`size ${size} is not supported`);
    }

    // OR using local (public folder)
    // -------------------------------------------------------
    let logo = (
      <Box
        component="img"
        src="/logo/logo.svg"
        sx={{ width: sizeMapping[size], cursor: 'pointer', ...sx }}
      />
    );

    let name = (
      <StyledTextGradient
        sx={{ fontSize: `${remMapping[size]}rem` }}
        animate={{ backgroundPosition: '200% center' }}
        transition={{
          repeatType: 'reverse',
          ease: 'linear',
          duration: 20,
          repeat: Infinity,
        }}
      >
        projectfit.ai
      </StyledTextGradient>
    );

    if (!disabledLink) {
      logo = (
        <Link
          component={RouterLink}
          href={pathname?.includes('dashboard') ? '/dashboard' : '/'}
          sx={{ display: 'contents' }}
        >
          {logo}
        </Link>
      );

      name = (
        <Link
          component={RouterLink}
          href={pathname?.includes('dashboard') ? '/dashboard' : '/'}
          sx={{ display: 'contents' }}
        >
          {name}
        </Link>
      );
    }

    return (
      <Stack direction="row" alignItems="center" spacing={1} {...other} ref={ref}>
        {logo}
        {includeName && name}
      </Stack>
    );
  }
);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  includeName: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.object,
};

export default Logo;
