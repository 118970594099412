import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, useTheme } from '@mui/material';

import { useScopes } from 'src/hooks/use-scopes';

// ----------------------------------------------------------------------

export default function GroupTabs({ tabDefinitons, metadata, currentTab, handleChangeTab }) {
  const theme = useTheme();
  const { hasScope } = useScopes();

  const tabs = useMemo(
    () =>
      (tabDefinitons || [])
        .filter((tab) => (tab.canAccess ? tab.canAccess(hasScope) : true))
        .map((tab) => ({
          ...tab,
          label: typeof tab.label === 'function' ? tab.label(theme, metadata) : tab.label,
        })),
    [hasScope, metadata, tabDefinitons, theme]
  );

  if (tabs.length === 0 || !tabs.some((tab) => tab.value === currentTab)) {
    return null;
  }

  return (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
      ))}
    </Tabs>
  );
}

GroupTabs.propTypes = {
  tabDefinitons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.func,
      icon: PropTypes.object,
      element: PropTypes.elementType,
      canAccess: PropTypes.func,
    })
  ),
  metadata: PropTypes.shape({
    hasApplied: PropTypes.bool,
  }),
  currentTab: PropTypes.string,
  handleChangeTab: PropTypes.func,
};
