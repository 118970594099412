import useSWR from 'swr';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';
import { generateHash } from 'src/utils/generate-object-hash';

import { useAuthContext } from 'src/auth/hooks';

export function useUser(sub, allowOriginalRequests = false) {
  const requestPayload = { sub };

  const storedRequestHash = useRef('');
  const { enqueueSnackbar } = useSnackbar();
  const { user, getAccessTokenSilently } = useAuthContext();

  const newRequestHash = generateHash(requestPayload);
  const isOriginalRequest = storedRequestHash.current !== newRequestHash;

  let params = [
    `/v1/users/${sub || user.sub}`,
    getAccessTokenSilently,
    enqueueSnackbar,
    {
      params: {
        original: allowOriginalRequests && isOriginalRequest,
      },
    },
  ];

  if (isOriginalRequest) {
    storedRequestHash.current = newRequestHash;
  }

  if (!sub && !user?.sub) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  if (!user) {
    return {
      data,
      error: null,
      isLoading: true,
      mutate,
    };
  }

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
