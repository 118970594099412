import { Helmet } from 'react-helmet-async';

import { RobertView } from 'src/sections/robert/view';

// ----------------------------------------------------------------------

export default function RobertPage() {
  return (
    <>
      <Helmet>
        <title>projectfit.ai | My name is Robert!</title>
      </Helmet>

      <RobertView />
    </>
  );
}
