import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { paths } from 'src/routes/paths';

import { useAuthContext } from 'src/auth/hooks';
import { HEADER } from 'src/layouts/config-layout';

import WaitlistHero from 'src/sections/waitlist/waitlist-hero';
import WaitlistInvite from 'src/sections/waitlist/waitlist-invite';

// ----------------------------------------------------------------------

const StyledPolygon = styled('div')(({ anchor = 'top', theme }) => ({
  left: 0,
  zIndex: 9,
  height: '80px',
  width: '100%',
  position: 'absolute',
  clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
  backgroundColor: theme.palette.background.default,
  display: 'block',
  lineHeight: 0,
  ...(anchor === 'bottom' && {
    bottom: -1,
    // backgroundColor: theme.palette.grey[900],
  }),
}));

// ----------------------------------------------------------------------
export default function WaitlistView() {
  const { authenticated } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const inviteRef = useRef();

  useEffect(() => {
    if (authenticated) {
      navigate(paths.dashboard.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (location?.hash === '#invitation' && inviteRef.current) {
      const timeout = window.setTimeout(() => {
        if (!inviteRef.current) {
          return;
        }

        inviteRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      return () => {
        window.clearTimeout(timeout);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Box sx={{ pt: { xs: `${HEADER.H_MOBILE}px`, md: `${HEADER.H_DESKTOP}px` } }}>
        <WaitlistHero />

        <Box sx={{ position: 'relative' }} ref={inviteRef}>
          <WaitlistInvite />
          <StyledPolygon anchor="bottom" />
        </Box>
      </Box>
    </>
  );
}
