import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Card,
  Alert,
  Stack,
  Button,
  Divider,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';
import { useSkills } from 'src/hooks/use-skills';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import SkillChip from 'src/components/skill-chip/skill-chip';
import AutocompleteSkills from 'src/components/autocomplete-skills';

// ----------------------------------------------------------------------

export default function OnboardingSubscriptions({ cvDocument, percentage, next, prev }) {
  const api = useApi();
  const loading = useBoolean(false);
  const hasChanged = useRef(false);
  const initialized = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, mutate: mutateUser } = useUser();
  const showSuggestionInfo = useBoolean(true);

  /**
   * Preselected Skills
   */
  const { data: preselectedSkillsRaw, isLoading: areSkillsLoading } = useSkills(
    user?.subscriptions
  );

  const preselectedSkills = useMemo(
    () =>
      (preselectedSkillsRaw?.data || []).map((skill) => ({
        id: skill._id,
        ...skill,
      })),
    [preselectedSkillsRaw?.data]
  );

  /**
   * Actual selected skills
   */
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [selectedSubscriptionIds, setSelectedSubscriptionIds] = useState([]);

  const addSelectedSubscription = useCallback(
    (skill) => {
      showSuggestionInfo.onFalse();
      let newSkills = [...selectedSubscriptions, skill];
      newSkills = newSkills.sort((a, b) => b.totalCount - a.totalCount);
      newSkills = newSkills.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);

      setSelectedSubscriptions(newSkills);
      hasChanged.current = true;
    },
    [selectedSubscriptions, showSuggestionInfo]
  );

  const removeSelectedSubscription = useCallback(
    (skillId) => {
      showSuggestionInfo.onFalse();
      setSelectedSubscriptions(selectedSubscriptions.filter((v) => v._id !== skillId));
      hasChanged.current = true;
    },
    [selectedSubscriptions, showSuggestionInfo]
  );

  useEffect(() => {
    setSelectedSubscriptionIds(selectedSubscriptions.map((v) => v._id));
  }, [selectedSubscriptions]);

  useEffect(() => {
    if (initialized.current === true || areSkillsLoading === true) {
      return;
    }

    initialized.current = true;
    setSelectedSubscriptions(preselectedSkills);
  }, [areSkillsLoading, preselectedSkills]);

  /**
   * Form handling
   */
  const onSubmit = async () => {
    if (!hasChanged.current && user?.onboarding?.subscriptions?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user.sub}`, {
        source: 'onboarding',
        subscriptions: selectedSubscriptions.map((v) => v._id),
        onboarding: {
          subscriptions: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="What are you interested in?" />
      <CardContent>
        Select up to 10 skills you&apos;re most interested in. Projects that require any of these
        skills will be automatically matched against your profile.
      </CardContent>

      <CardContent sx={{ pt: 0 }}>
        {areSkillsLoading && initialized.current === false ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {selectedSubscriptions.length === 10 ? (
              <Alert severity="success">
                You have reached the maximum number of skills. Please remove a skill to add a
                different one.
              </Alert>
            ) : (
              <AutocompleteSkills
                disabled={selectedSubscriptions.length === 10}
                label="Search for keywords (f.e. TypeScript, Prototyping, etc.)"
                hideOptions={selectedSubscriptionIds}
                onSelect={addSelectedSubscription}
              />
            )}

            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="left"
              spacing={1}
              sx={{ mt: 3 }}
            >
              {selectedSubscriptions.map((skill) => (
                <SkillChip key={skill._id} skill={skill} onDelete={removeSelectedSubscription} />
              ))}
            </Stack>
          </>
        )}
      </CardContent>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              onClick={prev}
            >
              Back
            </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LoadingButton
              loading={loading.value}
              disabled={selectedSubscriptions.length === 0}
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="eva:arrow-right-fill" />}
              onClick={onSubmit}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingSubscriptions.propTypes = {
  percentage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
