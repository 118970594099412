import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import { Stack } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useProjectGroup } from 'src/hooks/use-project-group';

import GroupInfoUserOpinion from 'src/pages/dashboard/management/group/info/user-opinion';
import GroupInfoMatchHeaderMatched from 'src/pages/dashboard/management/group/info/match-header-matched';
import GroupInfoMatchHeaderUnmatched from 'src/pages/dashboard/management/group/info/match-header-unmatched';

// ----------------------------------------------------------------------

export default function GroupInfoMatchHeader({ item, user }) {
  const api = useApi();
  const { mutate } = useProjectGroup(item._id, user?._id);
  const [inProgressLevel, setInProgressLevel] = useState();

  const opinion = item.userDetails?.opinion;
  const matching = item.userDetails?.matching;

  const hasMatching = typeof matching !== 'undefined' && matching?.status !== 'ABORTED';
  const hasAnyOpinion = typeof opinion !== 'undefined';
  const hasQualifiedOpinion = hasAnyOpinion && opinion?.level >= 0;

  const opinionShown = useBoolean(hasAnyOpinion || !hasMatching);

  const upsertOpinion = useCallback(
    async (rawLevel) => {
      if (typeof rawLevel === 'undefined') {
        return;
      }

      const level = parseInt(rawLevel, 10);

      if (hasQualifiedOpinion && opinion.level === level) {
        return;
      }

      // @todo write opinion directly to the group
      const posting = item.postings[0];

      setInProgressLevel(level);
      opinionShown.onTrue();

      try {
        const payload = {
          level: parseInt(level, 10),
          comment: '',
        };

        await api.put(`/v1/projects/${posting.projectId}/opinion`, payload, {
          params: { userId: user._id },
        });

        mutate();
      } catch (error) {
        console.error(error);
      } finally {
        setInProgressLevel(undefined);
      }
    },
    [api, hasQualifiedOpinion, item.postings, mutate, opinion?.level, opinionShown, user._id]
  );

  return (
    <Stack direction="column" spacing={0}>
      {/* Header Bar */}
      {hasMatching && <GroupInfoMatchHeaderMatched item={item} upsertOpinion={upsertOpinion} />}

      {!hasMatching && (
        <GroupInfoMatchHeaderUnmatched item={item} openOpinion={opinionShown.onTrue} />
      )}

      {/* User Opinion */}
      <GroupInfoUserOpinion
        item={item}
        shown={opinionShown.value}
        upsertOpinion={upsertOpinion}
        inProgressLevel={inProgressLevel}
      />
    </Stack>
  );
}

GroupInfoMatchHeader.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
