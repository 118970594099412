/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useCallback } from 'react';

import {
  Stack,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { formatOptions } from 'src/utils/format-osm-results';

import Iconify from 'src/components/iconify';

export function LocationInput({ initialValue, onSelect, ...args }) {
  const ref = useRef(null);
  const open = useBoolean(false);
  const selectedValue = useRef(initialValue);
  const currentValue = useRef(initialValue);
  const isDirty = useBoolean(false);
  const dataLoading = useBoolean(false);

  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [options, setOptions] = useState([]);

  const fetchOptions = async (value) => {
    if (value.length < 3) {
      setOptions([]);
      return;
    }

    dataLoading.onTrue();

    const url = new URL('https://nominatim.openstreetmap.org/search');
    url.searchParams.append('q', value);
    url.searchParams.append('format', 'json');
    url.searchParams.append('limit', 10);
    url.searchParams.append('countrycodes', 'de,at,ch');
    url.searchParams.append('addressdetails', 1);

    try {
      const response = await fetch(url.toString());
      const loadedOptions = await response.json();

      if (value !== currentValue.current) {
        return;
      }

      const formattedOptions = formatOptions(loadedOptions);

      setOptions(formattedOptions.length > 0 ? formattedOptions : []);
    } catch (error) {
      console.error(error);
    } finally {
      dataLoading.onFalse();
    }
  };

  useEffect(() => {
    open.setValue(searchTerm?.length >= 3);

    if (searchTerm?.length < 3) {
      setOptions([]);
      return () => {};
    }

    if (searchTerm === selectedValue.current) {
      setOptions([]);
      return () => {};
    }

    dataLoading.onTrue();

    // debounce for 1s and execute the callback
    const debounce = setTimeout(() => {
      fetchOptions(searchTerm);
    }, 1000);

    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm !== selectedValue.current) {
      isDirty.onTrue();
    }
  }, [isDirty, searchTerm]);

  useEffect(() => {
    currentValue.current = searchTerm;
  }, [searchTerm]);

  const handleChangeEvent = useCallback(
    (_, value, reason) => {
      if (!value.place_id) {
        return;
      }

      if (reason === 'selectOption') {
        onSelect(value);
        setSearchTerm(value.label);
        open.onFalse();
        isDirty.onFalse();
        selectedValue.current = value.label;
      }
    },
    [isDirty, onSelect, open]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const pickedOption = options.find(
          (option) => option.label.toLowerCase().trim() === searchTerm.toLowerCase().trim()
        );

        if (pickedOption) {
          onSelect(pickedOption);
          setSearchTerm('');
          open.onFalse();
        }
      }
    },
    [onSelect, open, options, searchTerm]
  );

  return (
    <Autocomplete
      ref={ref}
      freeSolo={false}
      fullWidth
      autoComplete
      clearOnEscape
      clearOnBlur
      // open state
      open={isDirty.value && open.value && dataLoading.value === false}
      onFocus={open.onTrue}
      onClose={open.onFalse}
      onChange={handleChangeEvent}
      onKeyDown={handleKeyDown}
      inputValue={searchTerm}
      onInputChange={(event, value, reason) => {
        if (reason === 'reset') {
          return;
        }

        setSearchTerm(value);
      }}
      // how to handle options
      isOptionEqualToValue={(option, value) => option?.place_id === value?.place_id}
      filterOptions={(x) => x}
      options={options}
      // loading state
      loading={dataLoading.value}
      noOptionsText={
        <Stack direction="row" spacing={1}>
          <Iconify icon="carbon:location-hazard" />
          <Typography key="1" variant="body2">
            No results found, please enter a valid location
          </Typography>
        </Stack>
      }
      // rendering
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location"
          margin="none"
          InputProps={{
            ...params.InputProps,
            sx: {
              backgroundColor: 'white',
            },
            endAdornment: (
              <InputAdornment position="end" sx={{ position: 'absolute', right: '9px' }}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  {dataLoading.value ? <CircularProgress color="inherit" size={20} /> : null}
                  {searchTerm.length > 0 && (
                    <IconButton size="small" onClick={() => setSearchTerm('')}>
                      <Iconify icon="carbon:close" />
                    </IconButton>
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={`${searchTerm}-${option?.place_id}`}>
          <Stack direction="row" spacing={1}>
            <Iconify icon="carbon:location" />
            <Typography key="1" variant="body2">
              {option.label}
            </Typography>
          </Stack>
        </li>
      )}
      {...args}
    />
  );
}

LocationInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};
