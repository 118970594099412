import PropTypes from 'prop-types';

import { Accordion } from '@mui/material';

import GroupInfoPostingHeader from 'src/pages/dashboard/management/group/info/posting-header';
import GroupInfoPostingContent from 'src/pages/dashboard/management/group/info/posting-content';

// ----------------------------------------------------------------------

export default function GroupInfoPostingItem({
  userSub,
  groupId,
  isExpanded,
  expand,
  postingCount,
  posting,
  appliedToPostings,
  selectedPostings,
  setSelectedPostings,
}) {
  return (
    <Accordion
      key={posting.projectId}
      expanded={isExpanded}
      onChange={expand}
      sx={[
        isExpanded
          ? {
              '& .MuiAccordionSummary-content': {
                my: '12px !important',
              },
              '&': {
                mt: '0 !important',
                mb: '0 !important',
                boxShadow: 'none !important',
              },
              '&::before': {
                opacity: '1 !important',
              },
            }
          : {},
      ]}
    >
      <GroupInfoPostingHeader
        userSub={userSub}
        posting={posting}
        postingCount={postingCount}
        appliedToPostings={appliedToPostings}
        selectedPostings={selectedPostings}
        setSelectedPostings={setSelectedPostings}
      />
      <GroupInfoPostingContent posting={posting} userSub={userSub} groupId={groupId} />
    </Accordion>
  );
}

GroupInfoPostingItem.propTypes = {
  selectedPostings: PropTypes.arrayOf(PropTypes.string),
  setSelectedPostings: PropTypes.func,
  appliedToPostings: PropTypes.arrayOf(PropTypes.string),
  postingCount: PropTypes.number,
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    distanceToUser: PropTypes.number,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
  isExpanded: PropTypes.bool,
  expand: PropTypes.func,
  userSub: PropTypes.string,
  groupId: PropTypes.string,
};
