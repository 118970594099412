import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Chip, CircularProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ProjectSkill({
  skill,
  source,
  userSub,
  showOnlyMissing,
  forceColor,
  size = 'medium',
  hideIcon = false,
  sx,
}) {
  const api = useApi();
  const isLoading = useBoolean(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, mutate: mutateUser } = useUser(userSub);

  const handleRemove = useCallback(async () => {
    isLoading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user.sub}`, {
        source,
        skills: (user?.skills || []).map((s) => s.toLowerCase()).includes(skill.toLowerCase())
          ? user.skills.filter((item) => item !== skill)
          : user.skills,
      });

      mutateUser(response.data);
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      isLoading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill, user.skills, user.sub]);

  const handleAdd = useCallback(async () => {
    isLoading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user.sub}`, {
        source,
        skills: (user?.skills || []).map((s) => s.toLowerCase()).includes(skill.toLowerCase())
          ? user.skills
          : [...user.skills, skill],
      });

      mutateUser(response.data);
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      isLoading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill, user.skills, user.sub]);

  if (showOnlyMissing && (user?.skills || []).includes(skill)) {
    return null;
  }

  if ((user?.skills || []).map((s) => s.toLowerCase()).includes(skill.toLowerCase())) {
    return (
      <Chip
        sx={sx}
        label={skill}
        size={size}
        title={hideIcon ? undefined : 'Remove this skill from your profile'}
        variant="soft"
        color={forceColor || 'success'}
        onDelete={hideIcon ? undefined : handleRemove}
        deleteIcon={
          // eslint-disable-next-line no-nested-ternary
          hideIcon ? null : isLoading.value ? (
            <CircularProgress size="16px" />
          ) : (
            <Iconify icon="eva:checkmark-outline" width={18} height={18} />
          )
        }
      />
    );
  }

  return (
    <Chip
      sx={sx}
      label={skill}
      size={size}
      title={hideIcon ? undefined : 'Add this skill to your profile'}
      variant="soft"
      color={forceColor || 'default'}
      onDelete={hideIcon ? undefined : handleAdd}
      deleteIcon={
        // eslint-disable-next-line no-nested-ternary
        hideIcon ? null : isLoading.value ? (
          <CircularProgress size="16px" />
        ) : (
          <Iconify icon="eva:plus-outline" width={18} height={18} />
        )
      }
    />
  );
}

ProjectSkill.propTypes = {
  source: PropTypes.oneOf(['project', 'project-group', 'profile']).isRequired,
  skill: PropTypes.string,
  size: PropTypes.string,
  userSub: PropTypes.string,
  forceColor: PropTypes.string,
  showOnlyMissing: PropTypes.bool,
  hideIcon: PropTypes.bool,
  sx: PropTypes.object,
};
