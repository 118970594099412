import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Stack, Tooltip, IconButton, CircularProgress } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useProjectGroup } from 'src/hooks/use-project-group';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetterContentHeadline({
  groupId,
  user,
  posting,
  item,
  sx,
}) {
  const api = useApi();
  const isLoadingBad = useBoolean();
  const isLoadingGood = useBoolean();
  const { mutate } = useProjectGroup(groupId, user._id);

  const setCopied = useCallback(async () => {
    try {
      await api.patch(
        `/v1/projects/${posting.projectId}/application-cover-letters/${item._id}`,
        { copied: true },
        { params: { userId: user._id } }
      );
    } catch (error) {
      // we ignore this error, because it's not mandatory
    }
  }, [api, item._id, posting.projectId, user._id]);

  const setSatisfied = useCallback(
    async (satisfied) => {
      if (satisfied) {
        isLoadingGood.onTrue();
        isLoadingBad.onFalse();
      } else {
        isLoadingGood.onFalse();
        isLoadingBad.onTrue();
      }

      try {
        await api.patch(
          `/v1/projects/${posting.projectId}/application-cover-letters/${item._id}`,
          { satisfied },
          { params: { userId: user._id } }
        );

        mutate();
      } catch (error) {
        console.error(error);
      } finally {
        isLoadingGood.onFalse();
        isLoadingBad.onFalse();
      }
    },
    [api, isLoadingBad, isLoadingGood, item._id, mutate, posting.projectId, user._id]
  );

  return (
    <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'nowrap', flexShrink: 0, ...sx }}>
      <Tooltip title="Copy to clipboard" arrow>
        <IconButton
          size="small"
          color="default"
          onClick={() => {
            navigator.clipboard.writeText(`${item?.title}\n\n${item?.description}`);
            setCopied();
          }}
        >
          <Iconify icon="eva:copy-outline" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Mark this cover letter as good" arrow>
        <IconButton
          size="small"
          color={item.satisfied === true ? 'success' : 'default'}
          disabled={isLoadingGood.value || isLoadingBad.value}
          onClick={() => {
            setSatisfied(true);
          }}
        >
          {isLoadingGood.value ? (
            <CircularProgress size={20} />
          ) : (
            <Iconify icon={item.satisfied === true ? 'prime:thumbs-up-fill' : 'prime:thumbs-up'} />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Mark this cover letter as bad" arrow>
        <IconButton
          size="small"
          color={item.satisfied === false ? 'error' : 'default'}
          disabled={isLoadingGood.value || isLoadingBad.value}
          onClick={() => {
            setSatisfied(false);
          }}
        >
          {isLoadingBad.value ? (
            <CircularProgress size={20} />
          ) : (
            <Iconify
              size={18}
              icon={item.satisfied === false ? 'prime:thumbs-down-fill' : 'prime:thumbs-down'}
            />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

GroupApplicationCoverLetterContentHeadline.propTypes = {
  sx: PropTypes.object,
  user: PropTypes.object,
  groupId: PropTypes.string,
  item: PropTypes.shape({
    _id: PropTypes.string,
    llmStatus: PropTypes.string,
    projectId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    satisfied: PropTypes.bool,
    copied: PropTypes.bool,
  }),
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
