import PropTypes from 'prop-types';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

import { Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { fPercent, fCurrency } from 'src/utils/format-number';

import { RenderCellBadge } from 'src/pages/dashboard/overview/inbox-list/row-elements';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export function RenderCellPromptComparison({ filterVersion, compareVersion }) {
  const filterVersionLabel = filterVersion
    ? `${filterVersion.version}: (${filterVersion.llmModel})`
    : 'All Versions';

  return (
    <Stack direction="row" alignItems="center" spacing={0.5} sx={{ display: 'flex', width: 1 }}>
      <Box sx={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }}>
        <Tooltip title={filterVersion?.comment} arrow placement="top">
          <Label
            variant="soft"
            color="default"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              width: 1,
              overflow: 'hidden',
              justifyContent: 'flex-start',
            }}
          >
            <Iconify icon="material-symbols-light:trip-origin" width={14} sx={{ mr: 0.5 }} />
            <LinesEllipsisLoose text={filterVersionLabel} maxLine="1" lineHeight="16" />
          </Label>
        </Tooltip>
      </Box>

      <Iconify icon="solar:arrow-right-bold" width={14} />

      <Box sx={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }}>
        <Tooltip title={compareVersion?.comment} arrow placement="top">
          <Label
            variant="soft"
            color="success"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              width: 1,
              overflow: 'hidden',
              justifyContent: 'flex-start',
            }}
          >
            <Iconify icon="mingcute:target-fill" width={14} sx={{ mr: 0.5 }} />
            <b>{compareVersion?.version}</b>: {compareVersion?.llmModel}
          </Label>
        </Tooltip>
      </Box>
    </Stack>
  );
}

RenderCellPromptComparison.propTypes = {
  filterVersion: PropTypes.object,
  compareVersion: PropTypes.object,
};

export function RenderCellPromptConfiguration({ params }) {
  const elements = [
    {
      title: 'Repeat Count',
      icon: 'solar:refresh-bold',
      value: params.row.repeatCount,
    },
    {
      title: 'Max Samples Count',
      icon: 'solar:square-double-alt-arrow-up-bold',
      value: params.row.sampleCount,
    },
    {
      title: 'Actual Samples Count',
      icon: 'solar:users-group-rounded-bold',
      value: (params.row.samples || []).length || '0',
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={0.5} sx={{ display: 'flex', width: 1 }}>
      {elements.map((element, index) => (
        <Box sx={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }} key={index}>
          <Tooltip title={element.title} arrow placement="top">
            <Label
              variant="soft"
              color="default"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                width: 1,
                overflow: 'hidden',
                justifyContent: 'space-between',
              }}
            >
              <Iconify icon={element.icon} width={14} sx={{ mr: 0.5 }} />
              {element.value}
            </Label>
          </Tooltip>
        </Box>
      ))}
    </Stack>
  );
}

RenderCellPromptConfiguration.propTypes = {
  params: PropTypes.object,
};

export function RenderCellPromptResults({ params }) {
  const colorMapping = (value) => {
    switch (true) {
      case typeof value === 'undefined':
        return 'default';
      case value > 0.7:
        return 'success';
      case value > 0.5:
        return 'info';
      case value > 0.3:
        return 'warning';
      default:
        return 'error';
    }
  };

  const elements = [
    {
      title: 'Overlaps with Opinions',
      icon: 'solar:users-group-rounded-bold',
      color: colorMapping(params.row.result?.opinionOverlaps),
      value:
        (params.row.samples || [])?.[0]?.opinion &&
        typeof params.row.result?.opinionOverlaps !== 'undefined'
          ? fPercent(params.row.result.opinionOverlaps * 100)
          : undefined,
    },
    {
      title: `Matching Score (${fPercent(
        ((params.row.result?.matchingScore || 0) / ((params.row.samples?.length || 1) * 10)) * 100
      )})`,
      icon: 'solar:diagram-up-line-duotone',
      color: colorMapping(
        (params.row.result?.matchingScore || 0) / ((params.row.samples?.length || 1) * 10)
      ),
      value:
        typeof params.row.result?.matchingScore !== 'undefined'
          ? `${params.row.result?.matchingScore?.toFixed(0)}`
          : undefined,
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={0.5} flexWrap="wrap">
      {elements.map((element, index) => (
        <Box sx={{ minWidth: '60px', flexGrow: 1, flexBasis: 0 }} key={index}>
          {element.value && (
            <Tooltip title={element.title} arrow placement="top">
              <Label
                variant="soft"
                color={element.color || 'default'}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: 1,
                  overflow: 'hidden',
                  justifyContent: 'space-between',
                }}
              >
                <Iconify icon={element.icon} width={14} sx={{ mr: 0.5, flexShrink: 0 }} />
                {element.value}
              </Label>
            </Tooltip>
          )}
        </Box>
      ))}
    </Stack>
  );
}

RenderCellPromptResults.propTypes = {
  params: PropTypes.object,
};

export function RenderCellPromptStatus({ params }) {
  const showSpinner = ['CREATED', 'CONFIRMED', 'PROCESSING'].includes(params.row.status);

  if (params.row.status === 'PROCESSING') {
    const totalLlmRequest = (params.row.samples || []).length * params.row.repeatCount;
    const actualLlmResults = Object.keys(params.row.llmResults || {}).length;
    const progress = Math.round((actualLlmResults / totalLlmRequest) * 100);

    return (
      <RenderCellBadge
        color="warning"
        fullWidth
        showSpinner={showSpinner}
        params={{ field: 'status', row: { status: `${progress}%` } }}
      />
    );
  }

  const colorMapping = {
    CREATED: 'default',
    INITIALIZED: 'info',
    CONFIRMED: 'default',
    PROCESSING: 'info',
    COMPLETED: 'success',
  };

  return (
    <RenderCellBadge
      fullWidth
      showSpinner={showSpinner}
      color={colorMapping[params.row.status]}
      params={params}
    />
  );
}

RenderCellPromptStatus.propTypes = {
  params: PropTypes.object,
};

export function RenderCellPromptCosts({ params }) {
  const elements = [
    {
      title: 'Estimated Costs',
      icon: 'solar:question-circle-bold',
      value:
        typeof params.row.costs?.estimatedCosts !== 'undefined'
          ? fCurrency(params.row.costs?.estimatedCosts || 0)
          : '??',
    },
    {
      title: 'Actual Costs',
      color: 'info',
      icon: 'solar:euro-bold',
      value:
        typeof params.row.costs?.actualCosts !== 'undefined'
          ? fCurrency(params.row.costs?.actualCosts || 0)
          : undefined,
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={0.5} sx={{ display: 'flex', width: 1 }}>
      {elements.map((element, index) => (
        <Box sx={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }} key={index}>
          {element.value && (
            <Tooltip title={element.title} arrow placement="top">
              <Label
                variant="soft"
                color={element.color || 'default'}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: 1,
                  overflow: 'hidden',
                  justifyContent: 'space-between',
                }}
              >
                <Iconify icon={element.icon} width={14} sx={{ mr: 0.5 }} />
                {element.value}
              </Label>
            </Tooltip>
          )}
        </Box>
      ))}
    </Stack>
  );
}

RenderCellPromptCosts.propTypes = {
  params: PropTypes.object,
};
