import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { useRouter } from 'src/routes/hooks';

import { bgGradient, textGradient } from 'src/theme/css';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import InvitationCodeInput from 'src/components/invitation-code-input';

// ----------------------------------------------------------------------

const InviteSchema = Yup.object().shape({
  code: Yup.string().min(6, 'invalid invite code').required('invite code is required'),
});

// ----------------------------------------------------------------------

export default function WaitlistInvite() {
  const router = useRouter();
  const theme = useTheme();
  const [validityCheck, setValidityCheck] = useState(null);
  const [searchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(InviteSchema),
    defaultValues: {
      code: searchParams.get('code') || '',
    },
  });

  const watchCode = methods.watch('code');

  const onSubmit = () => {
    router.push(`/register?code=${watchCode}`);
  };

  return (
    <Box
      sx={{
        minHeight: 560,
        overflow: 'hidden',
        position: 'relative',
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.8),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        clipPath: {
          xs: 'polygon(0 0, 100% 80px, 100% 100%, 0 100%)',
          md: 'polygon(0 0, 100% 80px, 100% 100%, 0 100%)',
          xl: 'polygon(0 0, 100% 80px, 100% 100%, 0 100%)',
        },
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                textAlign: { xs: 'center', md: 'unset' },
                mt: 20,
                mb: { xs: 0, md: 20 },
                maxWidth: { xs: 480, md: '100%' },
                mx: { xs: 'auto', md: 0 },
                pr: { xs: 0, md: 12 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: 0,
                  mb: 5,
                  ...textGradient(`300deg, #00A76F 0%, #007867 50%, #00A76F 100%`),
                }}
              >
                Already have an invitation code?
              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                Enter your invitation code to activate your AI assistant. Be one of the first to get
                access to a new way of finding perfect project fits.
              </Typography>
            </Box>
          </Grid>

          <Grid
            xs={12}
            md={6}
            sx={{
              mt: {
                xs: '80px',
                md: 0,
              },
              py: {
                pd: '80px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: 1,
                height: 1,
                alignItems: 'center',
                justifyContent: 'center',
                pb: { xs: 20, md: 0 },
              }}
            >
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={1.5} direction="column" sx={{ width: '350px', maxWidth: '100%' }}>
                  <InvitationCodeInput watchCode={watchCode} onValidityCheck={setValidityCheck} />

                  <Button
                    type="submit"
                    color="primary"
                    size="large"
                    variant="contained"
                    startIcon={<Iconify icon="solar:rocket-2-bold" width={24} />}
                    disabled={!validityCheck?.isValid}
                  >
                    Activate AI Assistant NOW
                  </Button>
                </Stack>
              </FormProvider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
