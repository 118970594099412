export function isUserProfileIncomplete(user) {
  return !userHasCvData(user) || !userHasEmailVerified(user);
}

export function userHasCvData(user) {
  return user?._id && ((user?.cv_raw || '').length > 0 || (user?.cv_parsed || '').length > 0);
}

export function userHasEmailVerified(user) {
  return user?.email_verified;
}
