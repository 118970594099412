import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack, Container } from '@mui/system';
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import {
  Card,
  Alert,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';
import { useWaitingList } from 'src/hooks/use-waiting-list';

import { RenderCellDate, RenderCellEllipsis } from 'src/pages/dashboard/admin/users/row';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function WaitingListAdminDashboardPage() {
  const api = useApi();
  const showDialog = useBoolean(false);
  const [dialogEmail, setDialogEmail] = useState(null);
  const discountCodesLoading = useBoolean();
  const sendingInvitationLoading = useBoolean();
  const [inviteError, setInviteError] = useState(null);
  const [selectedDiscountCode, setSelectedDiscountCode] = useState(null);
  const [discountCodes, setDiscountCodes] = useState(null);
  const { hasAllScopes } = useScopes();
  const settings = useSettingsContext();
  const { sub: userSub } = useParams();
  const { data: user } = useUser(userSub);
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageModel, setPageModel] = useState({
    page: (searchParams.get('page') || 1) - 1 || 0,
    pageSize: 25,
  });

  useEffect(() => {
    const expectedUrlPage = pageModel.page + 1;
    const actualUrlPage = searchParams.get('page') || 1;

    if (expectedUrlPage !== Number(actualUrlPage)) {
      setSearchParams({ page: pageModel.page + 1 }, { replace: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageModel]);

  useEffect(() => {
    const urlPage = searchParams.get('page') || 1;
    const expectedPage = urlPage - 1;

    if (expectedPage !== pageModel.page) {
      setPageModel({ page: expectedPage, pageSize: 25 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const [sortModel, setSortModel] = useState(
    localStorage.getItem(`users-list_${user?.sub}_sort`)
      ? JSON.parse(localStorage.getItem(`users-list_${user?.sub}_sort`))
      : [{ field: 'createdAt', sort: 'desc' }]
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  /**
   * WRITE FILTERS INTO LOCAL STORAGE
   */
  useEffect(() => {
    localStorage.setItem(`users-list_${user?.sub}_sort`, JSON.stringify(sortModel));
  }, [pageModel, sortModel, user?.sub]);

  /**
   * DATA FETCHING
   */
  const {
    data: waitingListRaw,
    isLoading: isWaitingListLoading,
    mutate: mutateWaitingList,
  } = useWaitingList(
    pageModel.page || 0,
    pageModel.pageSize || 10,
    sortModel?.[0]?.field || 'createdAt',
    sortModel?.[0]?.sort || 'desc'
  );

  const waitingList = useMemo(
    () =>
      (waitingListRaw?.data || []).map((v) => ({
        id: v._id,
        ...v,
      })),
    [waitingListRaw?.data]
  );

  const totalRowCount = useMemo(() => waitingListRaw?.metadata?.[0]?.total || 0, [waitingListRaw]);

  /**
   * VIEW
   */
  const columns = [
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      filterable: false,
      hideable: false,
      flex: 1,
      minWidth: 275,
      renderCell: (params) => <RenderCellEllipsis params={params} />,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      filterable: false,
      width: 150,
      renderCell: (params) => <RenderCellDate params={params} />,
    },
    {
      field: 'invited',
      headerName: 'Invited',
      filterable: false,
      width: 120,
      renderCell: (params) =>
        params.row.invited ? <Label color="success">Yes</Label> : <Label color="error">No</Label>,
    },
    {
      field: 'invitedAt',
      headerName: 'Invited At',
      filterable: false,
      width: 150,
      renderCell: (params) => <RenderCellDate params={params} />,
    },
    {
      field: 'user',
      headerName: 'Has User?',
      filterable: false,
      width: 150,
      renderCell: (params) =>
        params.row.user?._id ? <Label color="success">Yes</Label> : <Label color="error">No</Label>,
    },
    {
      type: 'actions',
      field: 'actions',
      headerName: ' ',
      align: 'right',
      headerAlign: 'right',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      getActions: (params) =>
        [
          hasAllScopes(['write:users', 'write:invitations', 'write:discounts']) ? (
            <GridActionsCellItem
              showInMenu
              icon={<Iconify icon="solar:code-square-bold" />}
              label="Send Invite Code"
              onClick={() => {
                setDialogEmail(params.row.emailAddress);
                showDialog.onTrue();
              }}
            />
          ) : null,
        ].filter((v) => !!v),
    },
  ];

  /**
   * LOADING DISCOUNT CODES AFTER THE INVITATION DIALOG HAS BEEN OPENED
   */
  const loadDiscountCodes = useCallback(async () => {
    discountCodesLoading.onTrue();

    try {
      const result = await api.get('/v1/discount-codes');
      setDiscountCodes(result.data.discounts);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      discountCodesLoading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showDialog.value || discountCodes !== null) {
      return;
    }

    loadDiscountCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog.value]);

  /**
   * Sending invitation code to the user
   */
  const sendInvitationCode = useCallback(async () => {
    sendingInvitationLoading.onTrue();

    try {
      await api.post('/v1/invite-user', {
        prefix: 'WL',
        emailAddress: dialogEmail,
        discountCode: selectedDiscountCode || undefined,
      });

      mutateWaitingList();
      setSelectedDiscountCode(null);
      setDialogEmail(null);

      showDialog.onFalse();
    } catch (error) {
      console.error(error);
      const message = error?.response?.data?.error || 'An error occurred. Please try again later.';
      setInviteError(message);
    } finally {
      sendingInvitationLoading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogEmail, selectedDiscountCode]);

  const getTogglableColumns = () =>
    columns.filter((column) => !['actions'].includes(column.field)).map((column) => column.field);

  return (
    <>
      <Helmet>
        <title>projectfit.ai | Admin: Waiting List</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomBreadcrumbs
          heading="Waiting List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Administration' },
            { name: 'Waiting List' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card
          sx={{
            height: { xs: 800, md: 2 },
            flexGrow: { md: 1 },
            display: { md: 'flex' },
            flexDirection: { md: 'column' },
          }}
        >
          <DataGrid
            sortingMode="server"
            paginationMode="server"
            checkboxSelection={false}
            disableRowSelectionOnClick
            rows={waitingList}
            rowCount={totalRowCount}
            columns={columns}
            loading={isWaitingListLoading}
            rowHeight={60}
            pageSizeOptions={[25]}
            onSortModelChange={(model) => {
              setSortModel(model);
            }}
            onPaginationModelChange={(model) => {
              setPageModel(model);
            }}
            paginationModel={pageModel}
            initialState={{
              sorting: {
                sortModel,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            slots={{
              toolbar: () => (
                <GridToolbarContainer>
                  <Stack
                    spacing={1}
                    flexGrow={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <GridToolbarColumnsButton />
                  </Stack>
                </GridToolbarContainer>
              ),
              noRowsOverlay: () => <EmptyContent title="No Data" />,
              noResultsOverlay: () => <EmptyContent title="No results found" />,
            }}
            slotProps={{
              columnsPanel: {
                getTogglableColumns,
              },
            }}
          />
        </Card>
      </Container>

      <Dialog open={showDialog.value} onClose={showDialog.onFalse}>
        <DialogTitle>Send Invitation Code</DialogTitle>

        <DialogContent>
          <TextField
            label="Email Address"
            value={dialogEmail}
            onChange={(e) => setDialogEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <Typography sx={{ my: 3 }}>
            Please pick a discount code that you would like to give the user.
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            flexWrap="wrap"
            spacing={1}
            sx={{ mb: 3 }}
          >
            {discountCodesLoading.value && <CircularProgress />}

            {!discountCodesLoading.value && (
              <Button
                variant="soft"
                color={selectedDiscountCode === null ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedDiscountCode(null);
                }}
              >
                NO CODE
              </Button>
            )}

            {(discountCodes || []).map((item) => (
              <Button
                key={item.code}
                variant="soft"
                color={selectedDiscountCode === item.code ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedDiscountCode(item.code);
                }}
              >
                {`${item.code} (-${item.percentOff}%)`}
              </Button>
            ))}
          </Stack>

          {inviteError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {inviteError}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={showDialog.onFalse} variant="outlined" color="inherit">
            Cancel
          </Button>

          <LoadingButton
            onClick={sendInvitationCode}
            loading={sendingInvitationLoading.value}
            variant="contained"
          >
            Send Invitation Code
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
