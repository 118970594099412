import { Alert } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { useLocalStorage } from 'src/hooks/use-local-storage';

import { bgGradient } from 'src/theme/css';
// ----------------------------------------------------------------------

const STORAGE_KEY = 'match-info-sending-hint';

// ----------------------------------------------------------------------

export default function GroupApplicationSendingHint() {
  const { state: hideInfo, update } = useLocalStorage(STORAGE_KEY, false);

  const theme = useTheme();

  if (hideInfo) {
    return null;
  }

  return (
    <Alert
      severity="success"
      onClose={() => {
        update(true);
      }}
      closeText="Do not show this message again"
      sx={{
        ...bgGradient({
          direction: '45deg',
          startColor: alpha(theme.palette.success.light, 0.2),
          endColor: alpha(theme.palette.success.main, 0.25),
        }),
      }}
    >
      <b>Great job, the first step is done!</b> <br />
      If you&apos;re happy with the letter, just copy it and send it through the platform.
    </Alert>
  );
}

GroupApplicationSendingHint.propTypes = {};
