import PropTypes from 'prop-types';

import { Stack, Button, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';
import MatchLevel from 'src/components/match-level';
import { getMatchLevelColor } from 'src/components/match-level/match-level';

// ----------------------------------------------------------------------

export default function GroupInfoMatchHeaderUnmatched({ item }) {
  const theme = useTheme();

  const opinion = item.userDetails?.opinion;
  const matching = item.userDetails?.matching;
  const { white } = theme.palette.common;
  const black = theme.palette.grey[700];

  const hasAnyOpinion = typeof opinion !== 'undefined';
  const hasQualifiedOpinion = hasAnyOpinion && opinion?.level >= 0;
  const shownMatchLevel = hasQualifiedOpinion ? opinion?.level : matching?.result?.level;
  const shownMatchStatus = hasQualifiedOpinion ? 'FINISHED' : matching?.status ?? 'ABORTED';
  const shownMatchAbortType = shownMatchStatus === 'ABORTED' ? matching?.abortType : undefined;
  const shownMatchAbortReason = shownMatchStatus === 'ABORTED' ? matching?.abortReason : undefined;
  const shownMatchAbortInfo = shownMatchStatus === 'ABORTED' ? matching?.abortInfo : undefined;
  const shownMatchBgColor = getMatchLevelColor(shownMatchLevel, theme);
  const shownMatchTextColor = typeof shownMatchLevel !== 'undefined' ? white : black;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ backgroundColor: shownMatchBgColor, p: 2 }}
      justifyContent="space-between"
    >
      <MatchLevel
        color={shownMatchTextColor}
        labelMode="long"
        labelSx={{ fontSize: 16, fontWeight: 'bold' }}
        hideLabel={false}
        robertLevel={undefined}
        status={shownMatchStatus}
        level={shownMatchLevel}
        abortType={shownMatchAbortType}
        abortReason={shownMatchAbortReason}
        abortInfo={shownMatchAbortInfo}
        direction="row"
      />

      <Button
        variant="soft"
        color="inherit"
        size="small"
        sx={{
          color: shownMatchTextColor,
          pointerEvents: hasQualifiedOpinion ? 'none' : 'auto',
          visibility: hasQualifiedOpinion ? 'visible' : 'hidden',
        }}
        endIcon={<Iconify icon="solar:check-circle-bold" color={shownMatchTextColor} />}
      >
        RATED
      </Button>
    </Stack>
  );
}

GroupInfoMatchHeaderUnmatched.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
};
