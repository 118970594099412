import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Box } from '@mui/system';
import Link from '@mui/material/Link';
import { Tooltip, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import matchLabel from 'src/utils/match-label';

// ----------------------------------------------------------------------

export default function PromptsBenchmarkSampleLlmItem({
  item,
  repeatCount,
  expectedLevel,
  llmResults,
  promptIdentifier,
  promptVersion,
  sample,
}) {
  const theme = useTheme();

  const llmRequestId = item.llmRef;
  const actualLevel = llmResults?.[llmRequestId]?.level || item.level;
  const actualStatus = llmResults?.[llmRequestId]?.llmStatus || item.llmStatus;

  const matchingColor = (expected, actual) =>
    expected === actual ? theme.palette.success.light : theme.palette.error.lighter;

  let backgroundColor = matchingColor(expectedLevel, actualLevel);

  let content = <Typography variant="caption">{matchLabel(actualLevel).split(' ')[0]}</Typography>;

  if (![0, 33, 66, 100].includes(actualLevel)) {
    backgroundColor = theme.palette.grey[100];
    content = (
      <Tooltip title={llmRequestId}>
        <span>...</span>
      </Tooltip>
    );
  }

  if (actualStatus === 'FAILED') {
    backgroundColor = theme.palette.error.main;
    content = (
      <Tooltip
        title={llmResults?.[llmRequestId]?.failedReason || item.failedReason}
        arrow
        placement="top"
      >
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          FAILED
        </Typography>
      </Tooltip>
    );
  }

  const link = paths.dashboard.admin.prompts.optimize;
  const linkParams = new URLSearchParams();
  linkParams.append('promptIdentifier', promptIdentifier);
  linkParams.append('promptVersion', promptVersion);
  linkParams.append('projectId', sample.project._id);
  linkParams.append('userId', sample.user._id);

  return (
    <Box
      sx={{
        width: `${100 / repeatCount}%`,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link color="inherit" variant="subtitle1" href={`${link}?${linkParams.toString()}`}>
        {content}
      </Link>
    </Box>
  );
}

PromptsBenchmarkSampleLlmItem.propTypes = {
  item: PropTypes.object.isRequired,
  repeatCount: PropTypes.number.isRequired,
  expectedLevel: PropTypes.number.isRequired,
  llmResults: PropTypes.object,
  sample: PropTypes.object.isRequired,
  promptIdentifier: PropTypes.string.isRequired,
  promptVersion: PropTypes.number.isRequired,
};
