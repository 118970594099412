import PropTypes from 'prop-types';

import { Stack, Tooltip, Typography } from '@mui/material';

import { fToNow } from 'src/utils/format-time';
import platformLabel from 'src/utils/platform-label';

// ----------------------------------------------------------------------

export default function GroupApplicationCreatePostingLabel({ posting }) {
  return (
    <Stack direction="row" width={1} alignItems="center" spacing={1}>
      {/* platform icon */}
      <Tooltip
        title={`This project has been found on ${platformLabel(posting.platform)}`}
        arrow
        placement="top"
      >
        <img
          alt={platformLabel(posting.platform)}
          src={`/assets/icons/platforms/${posting.platform}.png`}
          height="16"
          width="16"
          style={{ filter: 'grayscale(100%)' }}
        />
      </Tooltip>

      {/* title and metadata */}
      <span>
        <Typography component="span" variant="subtitle2" sx={{ fontWeight: '400' }}>
          {posting.title}
        </Typography>
        ,{' '}
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {fToNow(posting.postedAt)}
          {posting.details.authorCompany && ` by ${posting.details.authorCompany}`}
        </Typography>
      </span>
    </Stack>
  );
}

GroupApplicationCreatePostingLabel.propTypes = {
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
