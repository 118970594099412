import OnboardingName from 'src/sections/onboarding/steps/name';
import OnboardingFinish from 'src/sections/onboarding/steps/finish';
import OnboardingSkills from 'src/sections/onboarding/steps/skills';
import OnboardingDocument from 'src/sections/onboarding/steps/document';
import OnboardingExamples from 'src/sections/onboarding/steps/examples';
import OnboardingWorkLocation from 'src/sections/onboarding/steps/work-location';
import OnboardingNotifications from 'src/sections/onboarding/steps/notifications';
import OnboardingSubscriptions from 'src/sections/onboarding/steps/subscriptions';

const OnboardingSteps = {
  DOCUMENT: 'document',
  EXAMPLES: 'examples',
  SUBSCRIPTIONS: 'subscriptions',
  NAME: 'name',
  WORK_LOCATION: 'work-location',
  NOTIFICATIONS: 'notifications',
  SKILLS: 'skills',
  FINISH: 'finish',
};

const onboardingConfig = [
  {
    id: OnboardingSteps.DOCUMENT,
    component: OnboardingDocument,
    available: (hasScope) => hasScope('write:documents_own'),
    finished: (onboarding) => !!onboarding?.document?.finished,
  },
  {
    id: OnboardingSteps.EXAMPLES,
    component: OnboardingExamples,
    available: (_, cvDocument) => !!cvDocument && cvDocument?.status !== 'FAILED',
    finished: (onboarding) => !!onboarding?.examples?.finished,
  },
  {
    id: OnboardingSteps.SUBSCRIPTIONS,
    component: OnboardingSubscriptions,
    available: () => true,
    finished: (onboarding) => !!onboarding?.subscriptions?.finished,
  },
  {
    id: OnboardingSteps.EXAMPLES,
    component: OnboardingExamples,
    available: (_, cvDocument) => !cvDocument || cvDocument.status === 'FAILED',
    finished: (onboarding) => !!onboarding?.examples?.finished,
  },
  {
    id: OnboardingSteps.NAME,
    component: OnboardingName,
    available: () => true,
    finished: (onboarding) => !!onboarding?.name?.finished,
  },
  {
    id: OnboardingSteps.WORK_LOCATION,
    component: OnboardingWorkLocation,
    available: () => true,
    finished: (onboarding) => !!onboarding?.workLocation?.finished,
  },
  {
    id: OnboardingSteps.NOTIFICATIONS,
    component: OnboardingNotifications,
    available: () => true,
    finished: (onboarding) => !!onboarding?.notifications?.finished,
  },
  {
    id: OnboardingSteps.SKILLS,
    component: OnboardingSkills,
    available: () => true,
    finished: (onboarding) => !!onboarding?.skills?.finished,
  },
  {
    id: OnboardingSteps.FINISH,
    component: OnboardingFinish,
    available: () => true,
    finished: (onboarding) => !!onboarding?.total?.finished,
  },
];

export function getOnboardingConfig(hasScope, cvDocument, onboarding) {
  const availableSteps = onboardingConfig.filter((step) => step.available(hasScope, cvDocument));
  const defaultPage = onboardingConfig.find((step) => !step.finished(onboarding));

  return {
    availableSteps,
    defaultPage: defaultPage?.id || OnboardingSteps.FINISH,
  };
}
