import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export function useApi(includeAuth = true) {
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create({ baseURL: import.meta.env.VITE_HOST_API, timeout: 10000 });

  axiosInstance.interceptors.request.use(async (config) => {
    if (includeAuth) {
      const accessToken = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (!('Content-Type' in config.headers)) {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  });

  return axiosInstance;
}
