import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ExampleProject({ user, project, index, listCount }) {
  const skills = useMemo(
    () =>
      project.skills
        .map((label, i) => {
          const skillId = project.skillsReferences[i];
          return { label, isSubscribed: user.subscriptions.includes(skillId) };
        })
        .sort((a, b) => {
          if (a.isSubscribed && !b.isSubscribed) return -1;
          if (!a.isSubscribed && b.isSubscribed) return 1;
          return 0;
        })
        .slice(0, 5),
    [project.skills, project.skillsReferences, user.subscriptions]
  );

  return (
    <Box sx={{ pt: index === 0 ? 0 : 2, pb: index === listCount - 1 ? 0 : 2 }}>
      <Stack direction="column">
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {project.title}
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
          {skills.map((skill) => (
            <Tooltip
              arrow
              title={
                skill.isSubscribed
                  ? `This project has been selected for you because of your interest in ${skill.label}`
                  : null
              }
              key={skill.label}
            >
              <Chip
                size="small"
                variant={skill.isSubscribed ? 'filled' : 'soft'}
                color={skill.isSubscribed ? 'primary' : 'default'}
                key={skill.label}
                label={skill.label}
              />
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

ExampleProject.propTypes = {
  user: PropTypes.object,
  project: PropTypes.object,
  index: PropTypes.number,
  listCount: PropTypes.number,
};
