export const PROJECT_MATCH_LEVEL = [
  { value: 0, label: 'No Match' },
  { value: 33, label: 'Minimal Match' },
  { value: 66, label: 'Good Match' },
  { value: 100, label: 'Perfect Match' },
];

export const PROJECT_MATCH_LEVEL_SHORT = [
  { value: 0, label: 'None' },
  { value: 33, label: 'Minimal' },
  { value: 66, label: 'Good' },
  { value: 100, label: 'Perfect' },
];

export default function matchLabel(level, short = false) {
  const match = (short ? PROJECT_MATCH_LEVEL_SHORT : PROJECT_MATCH_LEVEL).find(
    (item) => item.value === level
  );

  return match ? match.label : 'Unknown';
}

export function matchLabelSentence(level) {
  return typeof level !== 'undefined'
    ? `This project is a ${matchLabel(level)} for you`
    : 'This project has not been matched against your profile';
}
