import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  login: '/login',
  logout: '/logout',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  imprint: '/imprint',
  privacy: '/privacy',
  waitlist: '/waitlist',
  register: '/register',
  onboarding: '/onboarding',
  emailConfirm: '/email-confirm',
  checkoutSuccess: '/checkout-success',
  checkoutCanceled: '/checkout-canceled',
  // AUTH
  auth: {
    auth0: {
      login: `/`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    management: {
      board: (sub) =>
        sub
          ? `${ROOTS.DASHBOARD}/management/board/${encodeURIComponent(sub)}`
          : `${ROOTS.DASHBOARD}/management/board`,
      group: (groupId, sub, tab, projectId) => {
        let path = `${ROOTS.DASHBOARD}/management/group/${encodeURIComponent(groupId)}`;
        if (sub) {
          path += `/${encodeURIComponent(sub)}`;
        }
        const queryParams = [];
        if (tab) {
          queryParams.push(`tab=${encodeURIComponent(tab)}`);
        }
        if (projectId) {
          queryParams.push(`posting=${encodeURIComponent(projectId)}`);
        }
        return path + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
      },
      invites: (sub) => `${ROOTS.DASHBOARD}/management/invites/${encodeURIComponent(sub)}`,
      billing: (sub) => `${ROOTS.DASHBOARD}/management/billing/${encodeURIComponent(sub)}`,
      profile: (sub) => `${ROOTS.DASHBOARD}/management/profile/${encodeURIComponent(sub)}`,
      subscriptions: (sub) =>
        `${ROOTS.DASHBOARD}/management/subscriptions/${encodeURIComponent(sub)}`,
      projects: (sub) => `${ROOTS.DASHBOARD}/management/projects/${encodeURIComponent(sub)}`,
      projectDetail: (sub, projectId) =>
        `${ROOTS.DASHBOARD}/management/projects/${encodeURIComponent(
          sub
        )}/detail/${encodeURIComponent(projectId)}`,
    },
    admin: {
      actionEvents: `${ROOTS.DASHBOARD}/admin/action-events`,
      statistics: `${ROOTS.DASHBOARD}/admin/statistics`,
      users: `${ROOTS.DASHBOARD}/admin/users`,
      waitingList: `${ROOTS.DASHBOARD}/admin/waiting-list`,
      settings: `${ROOTS.DASHBOARD}/admin/settings`,
      prompts: {
        list: `${ROOTS.DASHBOARD}/admin/prompts`,
        new: `${ROOTS.DASHBOARD}/admin/prompts/new`,
        optimize: `${ROOTS.DASHBOARD}/admin/prompts/optimize`,
        benchmarks: `${ROOTS.DASHBOARD}/admin/prompts/benchmarks`,
        benchmark: (id) => `${ROOTS.DASHBOARD}/admin/prompts/benchmarks/${id}`,
        edit: (id) => `${ROOTS.DASHBOARD}/admin/prompts/${id}`,
      },
      projectProviders: {
        list: `${ROOTS.DASHBOARD}/admin/project-providers`,
        new: `${ROOTS.DASHBOARD}/admin/project-providers/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/admin/project-providers/${id}`,
      },
      crawler: {
        crawl: `${ROOTS.DASHBOARD}/admin/crawl`,
      },
    },
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
