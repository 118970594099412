export const WORKLOAD_TYPES = [
  {
    value: 'full-time',
    label: 'Full-Time',
  },
  { value: 'part-time', label: 'Part-Time' },
  { value: 'hours-limited', label: 'Limited Hours' },
];

export default function workloadTypeLabel(values) {
  const valueList = Array.isArray(values) ? values : [values];

  return valueList
    .map((value) => {
      const item = WORKLOAD_TYPES.find((i) => i.value === value);
      return item ? item.label : 'Unknown';
    })
    .join(' and/or ');
}
