import { isDocumentInProgress } from 'src/hooks/use-documents';

export function cvFulfillsOnboarding(cvDocument, infoField) {
  // if the cv is undefined, or has a final state (PARSED|FAILED), we can return true (because cvs are optional and if it's final, we don't have to wait)
  if (!cvDocument || !isDocumentInProgress(cvDocument.status)) {
    return true;
  }

  // if the cv is in progress, we need to check if we could already extract the info we need
  const value = cvDocument?.extractedInfo?.[infoField]?.value;

  return typeof value !== 'undefined';
}
