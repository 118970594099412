import PropTypes from 'prop-types';

import { Box, Stack, useTheme, Typography, CircularProgress } from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function DocumentUploadStatus({ status, label }) {
  const theme = useTheme();

  let color;
  if (status === 'success') {
    color = theme.palette.success.main;
  }
  if (status === 'failed') {
    color = theme.palette.error.main;
  }
  if (status === 'pending') {
    color = theme.palette.text.disabled;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <Box
        sx={{
          width: '18px',
          height: '18px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {status === 'pending' && <Iconify icon="solar:menu-dots-bold" color={color} width={18} />}
        {status === 'processing' && <CircularProgress size={18} thickness={2.4} />}
        {status === 'success' && (
          <Iconify icon="eva:checkmark-outline" color={color} width={18} height={18} />
        )}
        {status === 'failed' && (
          <Iconify icon="eva:close-circle-fill" color={color} width={18} height={18} />
        )}
      </Box>
      <Typography
        variant="body2"
        color={color}
        sx={{ fontWeight: status === 'failed' ? 'bold' : undefined }}
        component="span"
      >
        {label}
        {status === 'processing' && '...'}
      </Typography>
    </Stack>
  );
}

DocumentUploadStatus.propTypes = {
  status: PropTypes.oneOf(['pending', 'processing', 'success', 'failed']),
  label: PropTypes.string,
};
