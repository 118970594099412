import { isInTrialPhase } from 'src/utils/is-in-trial-phase';
import { hasActiveSubscription } from 'src/utils/has-active-subscription';

export function canSeeProjectApply(hasScope) {
  return hasScope('read:application_cover_letters_own');
}

export function canUseProjectApplication(user, hasScope) {
  return (
    // user need to be able to see the project application feature
    canSeeProjectApply(hasScope) &&
    // user need to have rights to write project cover letters (either an admin for all or the user for itself)
    (hasScope('write:application_cover_letters') ||
      hasScope('write:application_cover_letters_own')) &&
    // user has to have an active subscription or trial phase
    (isInTrialPhase(user) || hasActiveSubscription(user))
  );
}
