import * as Yup from 'yup';
import { useState } from 'react';
import { m } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { Box, Container } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import { bgGradient, textGradient } from 'src/theme/css';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

const WaitlistSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('email address is required'),
});

const defaultValues = {
  emailAddress: '',
};

// ----------------------------------------------------------------------

const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(`300deg, #00A76F 0%, #007867 50%, #00A76F 100%`),
  padding: 0,
  marginTop: 0,
  marginBottom: 48,
  backgroundSize: '400%',
  lineHeight: 1.3,
  fontWeight: 900,
  letterSpacing: 0.7,
  fontSize: `3rem`,
  fontFamily: theme.typography.fontSecondaryFamily,
  [theme.breakpoints.up('sm')]: {
    fontSize: `4rem`,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: `5rem`,
  },
}));

// ----------------------------------------------------------------------

export default function WaitlistHero() {
  const api = useApi(false);
  const theme = useTheme();
  const isSuccess = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const methods = useForm({
    resolver: yupResolver(WaitlistSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessage(null);

    try {
      await api.post('/v1/waiting-list', { emailAddress: data.emailAddress });
      isSuccess.onTrue();
    } catch (error) {
      const message = error?.response?.data?.error || 'An error occurred. Please try again later.';
      setErrorMessage(message);
    }
  });

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.24,
          ...bgGradient({
            direction: '180deg',
            startColor: alpha(theme.palette.grey[900], 0),
            endColor: alpha(theme.palette.grey[900], 0.1),
          }),
        }}
      />

      <Grid
        container
        columnSpacing={{ xs: 0, md: 10 }}
        sx={{
          minHeight: { md: '65vh' },
          py: { xs: 10, md: 10 },
          overflow: 'hidden',
        }}
      >
        <Grid xs={12} md={12}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, mx: 'auto', maxWidth: 800, textAlign: 'center' }}
          >
            <Typography
              variant="h6"
              sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}
            >
              Your personal AI Project Scout
            </Typography>

            <StyledTextGradient
              animate={{ backgroundPosition: '200% center' }}
              transition={{
                repeatType: 'reverse',
                ease: 'linear',
                duration: 20,
                repeat: Infinity,
              }}
            >
              Take back your time with projectfit.ai
            </StyledTextGradient>

            <Typography variant="h4" sx={{ fontWeight: 'normal', maxWidth: 750 }}>
              Receive perfect project fits directly to your inbox – immediately. Stop wasting your
              time and start focus on what you love.
            </Typography>

            <Stack
              spacing={1.5}
              direction="column"
              sx={{ mt: 10, width: 1, maxWidth: '640px', textAlign: 'left' }}
            >
              {errorMessage && (
                <Alert severity="error" sx={{ width: 1 }}>
                  {errorMessage}
                </Alert>
              )}

              {isSuccess.value === true && (
                <Alert severity="success" sx={{ width: 1 }}>
                  <b>Thanks for joining our waitlist! We&apos;re excited to have you on board.</b>
                  <br />
                  You will now receive updates on our progress and be the first to know when we
                  launch.
                </Alert>
              )}

              {isSuccess.value === false && (
                <>
                  <Typography variant="subtitle1">
                    Join the waitlist and be the first to know when we launch.
                  </Typography>

                  <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Stack spacing={1.5} direction={{ xs: 'column', sm: 'row' }}>
                      <RHFTextField
                        name="emailAddress"
                        label="Enter your email"
                        inputProps={{
                          sx: { backgroundColor: 'white', borderRadius: '8px' },
                        }}
                      />

                      <LoadingButton
                        type="submit"
                        sx={{ flexShrink: 0, height: '53px' }}
                        loading={isSubmitting}
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        Request access
                      </LoadingButton>
                    </Stack>
                  </FormProvider>
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
