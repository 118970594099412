import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export function useSkills(ids, recommendationsForIds, from = 0, limit = 10) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();

  let params = [
    `/v1/skills`,
    getAccessTokenSilently,
    enqueueSnackbar,
    {
      params: {
        ids: (ids || []).join(','),
        recommendationsForIds: (recommendationsForIds || []).join(','),
        from,
        limit,
      },
    },
  ];

  if ((ids || []).length === 0 && (recommendationsForIds || []).length === 0) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
