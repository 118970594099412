/* eslint-disable no-bitwise */
// Funktion zum rekursiven Sortieren eines Objekts
function sortObject(obj) {
  if (Array.isArray(obj)) {
    return obj.map(sortObject); // Falls es ein Array ist, rekursiv sortieren
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj)
      .sort()
      .reduce((result, key) => {
        result[key] = sortObject(obj[key]);
        return result;
      }, {});
  }
  return obj;
}

// Funktion zum Erstellen eines stabilen Hashes (FNV-1a)
function fnv1aHash(str) {
  let hash = 0x811c9dc5; // Startwert für FNV-1a
  for (let i = 0; i < str.length; i += 1) {
    hash ^= str.charCodeAt(i);
    hash = (hash * 0x01000193) >>> 0; // 32-Bit-Hash
  }
  return hash.toString(16); // Hex-String zurückgeben
}

// Funktion zur Hash-Berechnung für ein JSON-Objekt
export function generateHash(obj) {
  const sortedObject = sortObject(obj); // JSON-Objekt sortieren
  const jsonString = JSON.stringify(sortedObject); // In JSON-String umwandeln
  return fnv1aHash(jsonString); // FNV-1a Hash des JSON-Strings berechnen
}
