export const WORKPLACE_TYPES = [
  { value: 'remote', label: 'Remote' },
  { value: 'on-site', label: 'On Site' },
  { value: 'hybrid', label: 'Hybrid' },
];

export default function workplaceTypeLabel(value) {
  const item = WORKPLACE_TYPES.find((i) => i.value === `${value}`.toLowerCase());

  return item ? item.label : 'Unknown';
}
