import { useRef } from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Button, MenuItem } from '@mui/material';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function ButtonDropdown({ prefixLabel, onChange, activeValue, items, ...args }) {
  const anchorRef = useRef();
  const popover = usePopover();

  const activeItem = items.find((item) => item.value === activeValue);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={popover.onOpen}
        variant="contained"
        color="primary"
        {...args}
      >
        <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
          {prefixLabel || ''}
          {activeItem.label}
          <Iconify icon="solar:alt-arrow-down-bold" />
        </Stack>
      </Button>

      {anchorRef.current && (
        <CustomPopover open={popover.open} anchorEl={anchorRef.current} onClose={popover.onClose}>
          <Box>
            {items.map((item) => (
              <MenuItem
                key={item.label}
                onClick={() => {
                  onChange(item.value);
                  popover.onClose();
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Box>
        </CustomPopover>
      )}
    </>
  );
}

ButtonDropdown.propTypes = {
  prefixLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
};
