import React from 'react';

export const emphasizeMatchingText = (text, matchingText) => {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (specialChars.test(matchingText)) {
    return text;
  }

  const matchRegex = RegExp(matchingText, 'ig');
  const matches = [...text.matchAll(matchRegex)];

  return text.split(matchRegex).map((nonBoldText, index, arr) => (
    <React.Fragment key={index}>
      {nonBoldText}
      {index + 1 !== arr.length && <b>{matches[index]}</b>}
    </React.Fragment>
  ));
};
