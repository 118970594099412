import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export function useWaitingList(page, pageSize, sort, order) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();

  const { data, error, isLoading, mutate } = useSWR(
    [
      `/v1/waiting-list`,
      getAccessTokenSilently,
      enqueueSnackbar,
      {
        params: {
          ...(typeof page !== 'undefined' && typeof pageSize !== 'undefined'
            ? { from: page * pageSize, limit: pageSize }
            : {}),
          ...(typeof sort !== 'undefined' && typeof order !== 'undefined' ? { sort, order } : {}),
        },
      },
    ],
    fetcher,
    swrConfig
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
