import Stack from '@mui/material/Stack';
import { Box, useTheme, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function NotificationsEmptyState() {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={0.5}
      flexGrow={1}
    >
      <Box
        sx={{
          width: 120,
          height: 120,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          mb: 2,
        }}
      >
        <Iconify icon="solar:bell-bold" width={120} height={120} color={theme.palette.grey[500]} />
        {/* check icon */}
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '40px',
            height: '40px',
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <Iconify
            icon="eva:checkmark-circle-2-fill"
            width={40}
            height={40}
            color={theme.palette.primary.main}
          />
        </Box>
      </Box>
      <Typography variant="h6">You&apos;re all caught up!</Typography>
      <Typography variant="body2">There are no new notifications at the moment.</Typography>
    </Stack>
  );
}
