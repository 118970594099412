import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Box, Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Container from '@mui/material/Container';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Card, Typography, CardHeader, CardContent } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLlmBenchmark } from 'src/hooks/use-llm-benchmark';

import PromptsBenchmarkSampleRow from 'src/pages/dashboard/admin/prompts-benchmark/sample-row';
import {
  RenderCellPromptCosts,
  RenderCellPromptStatus,
  RenderCellPromptResults,
  RenderCellPromptConfiguration,
} from 'src/pages/dashboard/admin/prompts-benchmark/list-elements';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function PromptsBenchmarkDetailAdminDashboardPage() {
  const settings = useSettingsContext();
  const api = useApi();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, mutate, isLoading } = useLlmBenchmark(params.id);
  const inProgress = useBoolean();

  /**
   * DATA UPDATE
   */
  const handlePlay = useCallback(
    async (id) => {
      inProgress.onTrue();

      try {
        await api.patch(`/v1/llm-benchmarks/${id}`, { status: 'CONFIRMED' });
        mutate();
      } catch (e) {
        enqueueSnackbar('Could not start benchmark!', { variant: 'error' });
      } finally {
        inProgress.onFalse();
      }
    },
    [api, enqueueSnackbar, inProgress, mutate]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  const hasOpinions = (data?.samples || []).some((sample) => sample.opinion);

  return (
    <>
      <Helmet>
        <title>projectfit.ai | Admin: Prompt Benchmark</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomBreadcrumbs
          heading="Benchmark Prompt"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Administration' },
            { name: 'Prompts', href: paths.dashboard.admin.prompts.list },
            { name: 'Benchmarks', href: paths.dashboard.admin.prompts.benchmarks },
            { name: 'Detail' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Stack direction="column" spacing={3}>
          <Card>
            <CardHeader
              title="Benchmark details"
              subheader={data?.comment}
              action={
                data?.status === 'INITIALIZED' ? (
                  <LoadingButton
                    variant="contained"
                    size="small"
                    startIcon={<Iconify icon="solar:play-bold" width={16} height={16} />}
                    loading={inProgress.value}
                    disabled={inProgress.value}
                    color="primary"
                    onClick={() => handlePlay(data._id)}
                  >
                    Start Execution
                  </LoadingButton>
                ) : null
              }
            />
            <CardContent>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={3}>
                  <Box sx={{ width: '150px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Status
                    </Typography>
                  </Box>

                  <Box sx={{ width: '200px' }}>
                    <RenderCellPromptStatus params={{ row: data, field: 'status' }} />
                  </Box>
                </Stack>

                <Stack direction="row" spacing={3}>
                  <Box sx={{ width: '150px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Configuration
                    </Typography>
                  </Box>

                  <Box sx={{ width: '200px' }}>
                    <RenderCellPromptConfiguration params={{ row: data }} />
                  </Box>
                </Stack>

                <Stack direction="row" spacing={3}>
                  <Box sx={{ width: '150px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Costs
                    </Typography>
                  </Box>

                  <Box sx={{ width: '200px' }}>
                    <RenderCellPromptCosts params={{ row: data }} />
                  </Box>
                </Stack>

                <Stack direction="row" spacing={3}>
                  <Box sx={{ width: '150px' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Results
                    </Typography>
                  </Box>

                  <Box sx={{ width: '200px' }}>
                    <RenderCellPromptResults params={{ row: data }} />
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Samples" />

            <CardContent>
              <Grid container spacing={3}>
                {/* Headline */}
                <Grid xs={5} />
                {hasOpinions && (
                  <Grid xs={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Opinion
                    </Typography>
                  </Grid>
                )}
                <Grid xs={1} sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Matching
                  </Typography>
                </Grid>
                <Grid xs={hasOpinions ? 5 : 6} sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Results
                  </Typography>
                </Grid>
                {/* Headline */}

                {(data?.samples || []).map((sample, index) => (
                  <PromptsBenchmarkSampleRow
                    key={`${sample.matching._id}-${index}`}
                    sample={sample}
                    llmResults={data.llmResults}
                    promptIdentifier={data.promptIdentifier}
                    promptVersion={data.comparePromptVersion}
                  />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
