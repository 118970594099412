import axios from 'axios';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useCallback } from 'react';

import { Box, Stack, Tooltip } from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDocuments } from 'src/hooks/use-documents';

import { Upload } from 'src/components/upload';
import DocumentRow from 'src/components/document-row';
import DocumentRowSkeleton from 'src/components/document-row-skeleton';

// ----------------------------------------------------------------------
const MAX_COUNT_DOCUMENTS = 3;
// ----------------------------------------------------------------------

export default function CvDocumentsSidebarUploadContent({ userSub }) {
  const { data: user } = useUser(userSub);
  const { hasAllScopes } = useScopes();

  const {
    data: documents,
    isLoading: documentsLoading,
    mutate: mutateDocuments,
  } = useDocuments(user?._id);

  // ---- file upload  ----

  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const inProgress = useBoolean(false);
  const hasChanged = useBoolean();
  const [file, setFile] = useState(null);

  const documentsCount = useMemo(() => documents?.data?.length || 0, [documents]);

  const uploadDocument = useCallback(
    async (uploadFile) => {
      inProgress.onTrue();
      hasChanged.onTrue();

      try {
        const preparation = await api.post(`/v1/documents`, {
          source: 'PROFILE',
          userId: user._id,
          type: 'CV',
          fileName: uploadFile.name,
          fileSize: uploadFile.size,
          fileType: uploadFile.type,
        });
        mutateDocuments();

        const { documentId, uploadUrl, downloadUrl } = preparation.data;

        if (!documentId || !uploadUrl || !downloadUrl) {
          console.error(preparation.data);
          throw new Error('Could not prepare document upload');
        }

        await axios.put(uploadUrl, uploadFile, {
          headers: {
            'Content-Type': uploadFile.file,
          },
        });

        await api.patch(`/v1/documents/${documentId}`, {
          userId: user._id,
          uploaded: true,
        });

        setFile(null);
        mutateDocuments();
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Could not upload document', { variant: 'error' });
      } finally {
        inProgress.onFalse();
      }
    },
    [api, enqueueSnackbar, hasChanged, inProgress, mutateDocuments, user._id]
  );

  const handleDropSingleFile = useCallback(
    (files) => {
      if (files.length < 1) {
        return;
      }

      const fileExtension = files[0].name.split('.').pop().toLowerCase();

      if (fileExtension !== 'pdf') {
        enqueueSnackbar('Only PDF files are allowed', { variant: 'error' });
        return;
      }

      setFile(files[0]);
      uploadDocument(files[0]);
    },
    [enqueueSnackbar, uploadDocument]
  );

  const handleRemoveFile = useCallback(() => setFile(null), []);

  if (!hasAllScopes(['read:documents_own', 'write:documents_own'])) {
    return null;
  }

  return (
    <>
      <Stack spacing={1} alignItems="flex-start" direction="column" sx={{ width: 1 }}>
        {documentsLoading &&
          Array.from({ length: 3 }).map((_, index) => <DocumentRowSkeleton key={index} />)}

        {!documentsLoading &&
          documents?.data?.map((document) => (
            <DocumentRow key={document._id} document={document} onDelete={mutateDocuments} />
          ))}
      </Stack>

      <Tooltip
        placement="top"
        arrow
        title={
          documentsCount >= MAX_COUNT_DOCUMENTS
            ? `You can only upload ${MAX_COUNT_DOCUMENTS} CVs`
            : ''
        }
      >
        <Box sx={{ width: 1, position: 'relative' }}>
          <Upload
            disabled={documentsCount >= MAX_COUNT_DOCUMENTS}
            accept={{ 'application/pdf': ['.pdf'] }}
            showIllustration={false}
            thumbnail={false}
            forceSpinner={!!file}
            slimPadding
            onDrop={handleDropSingleFile}
            onDelete={handleRemoveFile}
            placeholderTitle="Upload your CV"
          />
        </Box>
      </Tooltip>
    </>
  );
}

CvDocumentsSidebarUploadContent.propTypes = {
  userSub: PropTypes.string,
};
