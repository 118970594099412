import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';

import { useScopes } from 'src/hooks/use-scopes';
import { useDocuments } from 'src/hooks/use-documents';
import { useSampleCoverLetters } from 'src/hooks/use-sample-cover-letters';

import { canUseProjectApplication } from 'src/utils/access-helper';

import GroupApplicationSendingHint from 'src/pages/dashboard/management/group/application/sending-hint';
import GroupApplicationRoboIntroCv from 'src/pages/dashboard/management/group/application/robo-intro-cv';
import GroupApplicationCoverLetters from 'src/pages/dashboard/management/group/application/cover-letters';
import GroupApplicationRoboIntroStarted from 'src/pages/dashboard/management/group/application/robo-intro-started';
import GroupApplicationRoboIntroCoverLetter from 'src/pages/dashboard/management/group/application/robo-intro-cover-letter';

import { LoadingScreen } from 'src/components/loading-screen';

import CvDocumentsSidebarUpload from 'src/sections/cv-documents/sidebar-upload';
import SampleCoverLettersSidebarCard from 'src/sections/sample-cover-letters/sidebar-card';
import ProjectApplicationRoboIntroSmallPaywall from 'src/sections/project-application/robo-intro-small-paywall';

// ----------------------------------------------------------------------

export default function GroupApplyContent({ item, user }) {
  const { hasScope } = useScopes();

  const { data: documents, isLoading: documentsLoading } = useDocuments(user?._id);
  const { data: coverLetters, isLoading: coverLettersLoading } = useSampleCoverLetters(user?.sub);

  const [expandedItem, setExpandedItem] = useState(
    (item.userDetails?.applicationCoverLetters || [])?.[0]?._id
  );

  const handleChange =
    (panel) =>
    (_, isExpanded, force = false) => {
      if (!force && (item.userDetails?.applicationCoverLetters || []).length === 1) {
        return;
      }

      setExpandedItem(isExpanded ? panel : false);
    };

  const parsedDocumentsCount = useMemo(
    () => (documents?.data || []).filter((v) => v.status === 'PARSED').length || 0,
    [documents]
  );

  const coverLettersCount = useMemo(() => (coverLetters?.data || []).length || 0, [coverLetters]);
  const allowedToUse = canUseProjectApplication(user, hasScope);
  const allowedToCreate = parsedDocumentsCount > 0;

  if (coverLettersLoading || documentsLoading) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={8}>
        <Stack spacing={3} direction="column">
          {/* different introductions from robert */}
          {parsedDocumentsCount === 0 && <GroupApplicationRoboIntroStarted />}
          {parsedDocumentsCount > 0 && coverLettersCount === 0 && (
            <GroupApplicationRoboIntroCv
              handleChange={handleChange}
              groupId={item._id}
              postings={item.postings}
              user={user}
              showForm={allowedToUse && allowedToCreate}
            />
          )}
          {parsedDocumentsCount > 0 && coverLettersCount > 0 && (
            <GroupApplicationRoboIntroCoverLetter
              handleChange={handleChange}
              groupId={item._id}
              user={user}
              postings={item.postings}
              showForm={allowedToUse && allowedToCreate}
            />
          )}

          {/* paywall if user can't use it */}
          {!allowedToUse && <ProjectApplicationRoboIntroSmallPaywall userSub={user.sub} />}

          {/* show hint that you have to send the letter manually */}
          {item.userDetails?.applicationCoverLetters?.filter((v) => v.llmStatus === 'FINISHED')
            ?.length > 0 && <GroupApplicationSendingHint />}

          {/* list view of all cover letters */}
          {user?._id && (
            <GroupApplicationCoverLetters
              expandedItem={expandedItem}
              handleChange={handleChange}
              groupId={item._id}
              user={user}
              postings={item.postings}
              applicationCoverLetters={item.userDetails?.applicationCoverLetters || []}
            />
          )}
        </Stack>
      </Grid>
      <Grid xs={12} md={4}>
        <Stack spacing={3} direction="column">
          <CvDocumentsSidebarUpload sx={{ p: 2 }} userSub={user.sub} />
          <SampleCoverLettersSidebarCard sx={{ p: 2 }} userSub={user.sub} />
        </Stack>
      </Grid>
    </Grid>
  );
}

GroupApplyContent.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
