import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  Stack,
  Button,
  Divider,
  CardHeader,
  CardContent,
  LinearProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';

import { cvFulfillsOnboarding } from 'src/utils/cv-fulfills-onboarding';

import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import OptionalTooltip from 'src/components/optional-tooltip/optional-tooltip';

// ----------------------------------------------------------------------

export default function OnboardingName({ cvDocument, percentage, prev, next }) {
  const api = useApi();
  const { user: authUser } = useAuthContext();
  const { data: user, mutate: mutateUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const cvFulfilled = useMemo(() => cvFulfillsOnboarding(cvDocument, 'interests'), [cvDocument]);

  const CreateUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required'),
    jobTitle: Yup.string().required('Job title is required'),
  });

  const defaultValues = {
    fullName: user?.full_name || '',
    jobTitle: user?.job_title || '',
  };

  const methods = useForm({
    resolver: yupResolver(CreateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (user?.full_name === data.fullName && user?.onboarding?.name?.finished === true) {
      next();
      return;
    }

    try {
      const response = await api.patch(`/v1/users/${user.sub}`, {
        source: 'onboarding',
        full_name: data.fullName,
        job_title: data.jobTitle,
        avatar_url: authUser?.photoURL,
        onboarding: {
          name: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
        <CardHeader title="Who are you?" />
        <CardContent>
          To better match projects against your profile, we need to learn a bit more about you. To
          start, please tell us your name and current job title.
        </CardContent>

        <CardContent sx={{ pt: 0 }}>
          <Stack spacing={3}>
            <RHFTextField name="fullName" label="Name" />
            <RHFTextField name="jobTitle" label="Job title" />
          </Stack>
        </CardContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <CardContent>
          <Grid container sx={{ width: 1 }}>
            <Grid
              xs={4}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            >
              {prev && (
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={<Iconify icon="eva:arrow-left-fill" />}
                  onClick={prev}
                >
                  Back
                </Button>
              )}
            </Grid>

            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LinearProgress
                color="inherit"
                value={percentage}
                variant="determinate"
                sx={{ width: '60px' }}
              />
            </Grid>

            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <OptionalTooltip
                show={!cvFulfilled}
                title="We're currently processing your cv, this will take a few seconds"
              >
                <LoadingButton
                  type="submit"
                  loading={isSubmitting || !cvFulfilled}
                  variant="contained"
                  color="primary"
                  endIcon={<Iconify icon="eva:arrow-right-fill" />}
                  onClick={onSubmit}
                >
                  Continue
                </LoadingButton>
              </OptionalTooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

OnboardingName.propTypes = {
  percentage: PropTypes.number,
  prev: PropTypes.func,
  next: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
