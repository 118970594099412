/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { useAuthContext } from 'src/auth/hooks';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
export default function GuestGuard({ children }) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
