import nl2br from 'react-nl2br';
import PropTypes from 'prop-types';

import { Box, Stack, Alert, alpha, useTheme, Typography, AccordionDetails } from '@mui/material';

import { bgGradient } from 'src/theme/css';
import GroupApplicationCoverLetterContentHeadline from 'src/pages/dashboard/management/group/application/cover-letter-content-headline';
import GroupApplicationCoverLetterContentPersonality from 'src/pages/dashboard/management/group/application/cover-letter-content-personality';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetterContent({
  index,
  user,
  groupId,
  posting,
  item,
}) {
  const theme = useTheme();

  if (['INITIALIZED', 'PROCESSING'].includes(item.llmStatus)) {
    return <AccordionDetails sx={{ p: 0 }} />;
  }

  if (['FAILED'].includes(item.llmStatus)) {
    return (
      <AccordionDetails sx={{ p: 2 }}>
        <Stack spacing={0.5} alignItems="flex-start" direction="column">
          <Alert
            severity="error"
            sx={{
              width: 1,
              ...bgGradient({
                direction: '45deg',
                startColor: alpha(theme.palette.error.light, 0.2),
                endColor: alpha(theme.palette.error.main, 0.25),
              }),
            }}
          >
            We are really sorry, the generation of the cover letter has failed.
          </Alert>
        </Stack>
      </AccordionDetails>
    );
  }

  return (
    <AccordionDetails sx={{ p: 2 }}>
      <Stack direction="column" spacing={2}>
        <Box>
          <GroupApplicationCoverLetterContentHeadline
            groupId={groupId}
            user={user}
            posting={posting}
            item={item}
            sx={{ float: 'right' }}
          />
          <Typography variant="body2">{nl2br(item?.description)}</Typography>
        </Box>

        <GroupApplicationCoverLetterContentPersonality index={index} user={user} />
      </Stack>
    </AccordionDetails>
  );
}

GroupApplicationCoverLetterContent.propTypes = {
  index: PropTypes.number,
  user: PropTypes.object,
  groupId: PropTypes.string,
  item: PropTypes.shape({
    _id: PropTypes.string,
    llmStatus: PropTypes.string,
    projectId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
