import * as Sentry from '@sentry/react';

import { useApi } from 'src/hooks/use-api';

export function useActionEvents() {
  const api = useApi();

  const dispatch = async (type, attributes = {}) => {
    Object.keys(attributes).forEach((key) => {
      if (typeof attributes[key] === 'undefined') {
        delete attributes[key];
      }
    });

    try {
      await api.post('/v1/action-events', { type, attributes });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return { dispatch };
}
