import PropTypes from 'prop-types';

import { Accordion } from '@mui/material';

import GroupApplicationCoverLetterHeader from 'src/pages/dashboard/management/group/application/cover-letter-header';
import GroupApplicationCoverLetterContent from 'src/pages/dashboard/management/group/application/cover-letter-content';

// ----------------------------------------------------------------------

export default function GroupApplicationCoverLetterItem({
  index,
  posting,
  groupId,
  user,
  letterCount,
  item,
  isExpanded,
  expand,
}) {
  return (
    <Accordion
      key={item._id}
      expanded={isExpanded}
      onChange={expand}
      sx={[
        isExpanded
          ? {
              '& .MuiAccordionSummary-root': {
                minHeight: '0 !important',
              },
              '& .MuiAccordionSummary-content': {
                my: '12px !important',
              },
              '&': {
                mt: '0 !important',
                mb: '0 !important',
                boxShadow: 'none !important',
              },
              '&::before': {
                opacity: '1 !important',
              },
            }
          : {},
      ]}
    >
      <GroupApplicationCoverLetterHeader posting={posting} item={item} letterCount={letterCount} />

      <GroupApplicationCoverLetterContent
        index={index}
        groupId={groupId}
        user={user}
        posting={posting}
        item={item}
      />
    </Accordion>
  );
}

GroupApplicationCoverLetterItem.propTypes = {
  index: PropTypes.number,
  user: PropTypes.object,
  groupId: PropTypes.string,
  isExpanded: PropTypes.bool,
  expand: PropTypes.func,
  letterCount: PropTypes.number,
  item: PropTypes.shape({
    _id: PropTypes.string,
    projectId: PropTypes.string,
    llmStatus: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
