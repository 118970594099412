import PropTypes from 'prop-types';

import { Stack, Button, useTheme } from '@mui/material';

import ButtonDropdown from 'src/components/button-dropdown/button-dropdown';

const allowedValues = [
  { value: 'remote', label: 'Remote' },
  { value: 'hybrid', label: 'Hybrid' },
  { value: 'on-site', label: 'On-Site' },
];

const radiusValues = [
  { value: 25, label: '25km' },
  { value: 50, label: '50km' },
  { value: 100, label: '100km' },
  { value: 250, label: '250km' },
  { value: undefined, label: 'infinite' },
];

const radiusOptions = ['hybrid', 'on-site'];

export function WorkLocationToggle({ activeValues, toggleValue, changeRadius, ...args }) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
      flexWrap="wrap"
      display="flex"
      width={1}
      {...args}
    >
      {allowedValues.map((item) => {
        const activeItem = activeValues.find((v) => v.value === item.value);

        return (
          <Stack direction="row" spacing={0} alignItems="center" flexWrap="nowrap" key={item.value}>
            <Button
              key={item.value}
              onClick={() => toggleValue(item.value)}
              variant={activeItem.enabled ? 'contained' : 'soft'}
              color={activeItem.enabled ? 'primary' : 'inherit'}
              sx={{
                ...(activeItem.enabled && radiusOptions.includes(item.value)
                  ? {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : {}),
              }}
            >
              {item.label}
            </Button>

            {activeItem.enabled && radiusOptions.includes(item.value) && (
              <ButtonDropdown
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                activeValue={activeItem.radius}
                items={radiusValues}
                onChange={(value) => changeRadius(item.value, value)}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

WorkLocationToggle.propTypes = {
  activeValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      radius: PropTypes.number,
    })
  ),
  toggleValue: PropTypes.func.isRequired,
  changeRadius: PropTypes.func.isRequired,
};
