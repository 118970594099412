import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, title, ...other }) {
  return (
    <>
      <Helmet>
        <title>{title || 'projectfit | Loading...'}</title>
      </Helmet>

      <Box
        sx={{
          px: 5,
          width: 1,
          flexGrow: 1,
          minHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        {...other}
      >
        <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
      </Box>
    </>
  );
}

LoadingScreen.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object,
};
