import useSWR from 'swr';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export const DOCUMENTS_FINAL_STATUS = ['PARSED', 'FAILED'];

export function isDocumentInProgress(status) {
  return !DOCUMENTS_FINAL_STATUS.includes(status);
}

export function useDocuments(userId, page = 0, pageSize = 25, sort = 'createdAt', order = 'desc') {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();

  const params = [
    `/v1/documents`,
    getAccessTokenSilently,
    enqueueSnackbar,
    {
      params: {
        ...(typeof page !== 'undefined' && typeof pageSize !== 'undefined'
          ? { from: page * pageSize, limit: pageSize }
          : {}),
        ...(typeof sort !== 'undefined' && typeof order !== 'undefined' ? { sort, order } : {}),
        userId,
      },
    },
  ];

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  const hasProcessingData = (data?.data || []).some((doc) => isDocumentInProgress(doc.status));

  useEffect(() => {
    if (hasProcessingData) {
      const timeout = setInterval(() => {
        mutate();
      }, 2500);

      return () => clearInterval(timeout);
    }

    return () => {};
  }, [hasProcessingData, mutate]);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
