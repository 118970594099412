import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { RouterLink } from 'src/routes/components';

import { useAuthContext } from 'src/auth/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

export default function LoginButton({ label = 'Login', sx }) {
  const { authenticated, loginWithRedirect } = useAuthContext();

  const handleLoginWithRedirect = useCallback(async () => {
    try {
      await loginWithRedirect?.({
        appState: {
          returnTo: PATH_AFTER_LOGIN,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [loginWithRedirect]);

  if (authenticated) {
    return (
      <Button component={RouterLink} href="/dashboard" variant="outlined" sx={{ mr: 1, ...sx }}>
        {label}
      </Button>
    );
  }

  return (
    <Button onClick={handleLoginWithRedirect} variant="outlined" sx={{ mr: 1, ...sx }}>
      {label}
    </Button>
  );
}

LoginButton.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
};
