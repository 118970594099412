import { Helmet } from 'react-helmet-async';

import { Stack, Container } from '@mui/system';

// ----------------------------------------------------------------------

export default function ImprintPage() {
  /* eslint-disable prettier/prettier */
  return (
    <>
      <Helmet>
        <title>projectfit.ai | Imprint</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          sx={{
            maxWidth: 720,
            mx: 'auto',
            mt: { xs: 5, md: 10 },
          }}
        >
          <h1>Site Notice</h1>

          <p>
            Christian Schuhbaum
            <br />
            Waldmeisterstr. 31
            <br />
            80935 M&uuml;nchen
          </p>

          <h2>Contact</h2>
          <p>
            Phone: +49 151 194 25 530
            <br />
            E-mail: info@projectfit.ai
          </p>

          <h2>VAT ID</h2>
          <p>
            Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
            <br />
            DE327923754
          </p>

          <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
          <p>
            We are not willing or obliged to participate in dispute resolution proceedings in front
            of a consumer arbitration board.
          </p>
        </Stack>
      </Container>
    </>
  );
}
