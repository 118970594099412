import nl2br from 'react-nl2br';
import PropTypes from 'prop-types';

import { Box, Stack, Button, Divider, Typography, AccordionDetails } from '@mui/material';

import GroupInfoPostingFacts from 'src/pages/dashboard/management/group/info/posting-facts';
import GroupInfoApplicationHint from 'src/pages/dashboard/management/group/info/application-hint';

import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';

// ----------------------------------------------------------------------

export default function GroupInfoPostingContent({ userSub, groupId, posting }) {
  return (
    <AccordionDetails sx={{ p: 0 }}>
      <Stack direction="column" spacing={2} sx={{ py: 2 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          sx={{ px: 2 }}
        >
          <GroupInfoPostingFacts posting={posting} />

          {/* on mobile, we do have a button to open the source link */}
          <Stack direction="row">
            <Box>
              <Button
                href={posting.sourceLink}
                variant="soft"
                sx={{ mr: 1, flexShrink: 0, display: { xs: undefined, md: 'none' } }}
                target="_blank"
                startIcon={<Iconify icon="eva:external-link-fill" width={16} />}
              >
                Open
              </Button>
            </Box>

            <GroupInfoApplicationHint userSub={userSub} groupId={groupId} posting={posting} />
          </Stack>
        </Stack>

        <Divider />

        <Box sx={{ px: 2 }}>
          {posting.descriptionType === 'markdown' ? (
            <Typography variant="body2">
              <Markdown
                sx={{
                  '& p, & li, & ol': {
                    typography: 'body2',
                  },
                  '& p': {
                    my: 3,
                  },
                  '& p:first-of-type': {
                    mt: 0,
                  },
                  '& p:last-of-type': {
                    mb: 0,
                  },
                  '& br': {
                    m: 0,
                  },
                }}
              >
                {posting.description}
              </Markdown>
            </Typography>
          ) : (
            <Typography variant="body2">{nl2br(posting.description)}</Typography>
          )}
        </Box>
      </Stack>
    </AccordionDetails>
  );
}

GroupInfoPostingContent.propTypes = {
  posting: PropTypes.shape({
    projectId: PropTypes.string,
    postedAt: PropTypes.string,
    platform: PropTypes.string,
    sourceLink: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    descriptionType: PropTypes.oneOf(['text', 'markdown']),
    distanceToUser: PropTypes.number,
    details: PropTypes.shape({
      descriptionLanguage: PropTypes.string,
      authorCompany: PropTypes.string,
      authorName: PropTypes.string,
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
  userSub: PropTypes.string,
  groupId: PropTypes.string,
};
