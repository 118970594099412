import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Card,
  Chip,
  Button,
  Divider,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useUser } from 'src/hooks/use-user';
import { useSkills } from 'src/hooks/use-skills';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function OnboardingSkills({ cvDocument, percentage, prev, next }) {
  const api = useApi();
  const loading = useBoolean(false);
  const hasChanged = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, isLoading: isUserLoading, mutate: mutateUser } = useUser();
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [availableSkills, setAvailableSkills] = useState(null);

  const cvSkillReferences = useMemo(
    () => (cvDocument?.extractedInfo?.skillsReferences?.value || []).slice(0, 25),
    [cvDocument?.extractedInfo?.skillsReferences?.value]
  );

  const { data: cvSkills, isLoading: areCvSkillsLoading } = useSkills(
    cvSkillReferences,
    undefined,
    0,
    25
  );
  const { data: recommendedSkills, isLoading } = useSkills(null, user.subscriptions, 0, 50);

  useEffect(() => {
    // only set the available skills once
    if (availableSkills) {
      return;
    }

    // only set the available skills once the cv and recommended skills are loaded
    if (areCvSkillsLoading || isLoading) {
      return;
    }

    const list = [
      ...(cvSkills?.data || []),
      ...(recommendedSkills?.data || []).filter((item) => !cvSkillReferences.includes(item._id)),
    ].slice(0, 50);

    setAvailableSkills(list);
  }, [
    cvSkills,
    availableSkills,
    recommendedSkills,
    areCvSkillsLoading,
    isLoading,
    cvSkillReferences,
  ]);

  useEffect(() => {
    if (!isUserLoading && !selectedSkills && (user?.skills || []).length > 0) {
      setSelectedSkills(user?.skills || []);
    }
  }, [isUserLoading, selectedSkills, user?.skills]);

  const toggleSkills = (skillLabel) => {
    hasChanged.onTrue();

    if ((selectedSkills || []).some((item) => item.toLowerCase() === skillLabel.toLowerCase())) {
      setSelectedSkills(
        (selectedSkills || []).filter((item) => item.toLowerCase() !== skillLabel.toLowerCase())
      );
    } else {
      setSelectedSkills([...(selectedSkills || []), skillLabel]);
    }
  };

  const onSubmit = async () => {
    if (!hasChanged.value && user?.onboarding?.skills?.finished === true) {
      next();
      return;
    }

    loading.onTrue();

    try {
      const response = await api.patch(`/v1/users/${user.sub}`, {
        source: 'onboarding',
        skills: selectedSkills,
        onboarding: {
          skills: {
            finished: true,
          },
        },
      });

      mutateUser(response.data);
      next();
    } catch (error) {
      enqueueSnackbar('Update failed!', { variant: 'error' });
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  return (
    <Card sx={{ width: '480px', maxWidth: 'calc(100vw - 32px)', textAlign: 'left' }}>
      <CardHeader title="What about your expertise?" />
      <CardContent>
        To ensure the best possible matches, it&apos;s important that we understand your skills.
        Below is a curated list of keywords most commonly required in the projects you&apos;re
        interested in.
      </CardContent>

      <CardContent sx={{ pt: 0 }}>
        {(isLoading || areCvSkillsLoading) && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && !areCvSkillsLoading && (
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="left"
            spacing={1}
          >
            {(availableSkills || []).map((skill) => {
              const enabled = (selectedSkills || []).some(
                (item) => item.toLowerCase() === skill.label.toLowerCase()
              );

              return (
                <Chip
                  key={skill.label}
                  label={skill.label}
                  variant="soft"
                  color={enabled ? 'primary' : 'default'}
                  onDelete={() => toggleSkills(skill.label)}
                  onClick={() => toggleSkills(skill.label)}
                  deleteIcon={
                    enabled ? (
                      <Iconify icon="eva:checkmark-outline" width={18} height={18} />
                    ) : (
                      <Iconify icon="eva:plus-outline" width={18} height={18} />
                    )
                  }
                />
              );
            })}
          </Stack>
        )}
      </CardContent>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <CardContent>
        <Grid container sx={{ width: 1 }}>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
              onClick={prev}
            >
              Back
            </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LinearProgress
              color="inherit"
              value={percentage}
              variant="determinate"
              sx={{ width: '60px' }}
            />
          </Grid>

          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <LoadingButton
              loading={loading.value}
              disabled={(selectedSkills || []).length === 0}
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="eva:arrow-right-fill" />}
              onClick={onSubmit}
            >
              Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

OnboardingSkills.propTypes = {
  percentage: PropTypes.number,
  prev: PropTypes.func,
  next: PropTypes.func,
  cvDocument: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fileInfo: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      filePath: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      fileType: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    textContent: PropTypes.string,
    downloadUrl: PropTypes.string,
    error: PropTypes.string,
    extractedInfo: PropTypes.shape({
      check: PropTypes.shape({
        value: PropTypes.bool,
      }),
      checkInfo: PropTypes.shape({
        value: PropTypes.string,
      }),
      summary: PropTypes.shape({
        value: PropTypes.string,
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string,
      }),
      jobTitle: PropTypes.shape({
        value: PropTypes.string,
      }),
      interests: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      interestsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skills: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
      skillsReferences: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};
