import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export function useUsersInvites(sub) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();

  let params = [`/v1/users/${sub}/invites`, getAccessTokenSilently, enqueueSnackbar];

  if (!sub) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
