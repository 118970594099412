import PropTypes from 'prop-types';

import { Stack, IconButton, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import { LocationInput } from 'src/components/location-input/location-input';

export function UserLocationForm({
  activeAddressLabel,
  formShown,
  toggleForm,
  onChangeAddress,
  alignItems = { xs: 'flex-start', md: 'flex-end' },
  textAlign = { xs: 'left', md: 'right' },
  ...args
}) {
  return (
    <Stack direction="column" spacing={2} alignItems={alignItems} {...args}>
      {activeAddressLabel && (
        <Stack direction="column" spacing={0} alignItems={alignItems}>
          <Typography variant="body2" color="textSecondary">
            We&apos;re filtering your projects based on this address:
          </Typography>

          <Stack direction="row" alignItems="center" spacing={0.5} justifyContent={alignItems}>
            <Typography variant="body2" color="textPrimary" fontWeight="500" textAlign={textAlign}>
              {activeAddressLabel}
            </Typography>

            <IconButton size="small" onClick={toggleForm} disabled={formShown}>
              <Iconify icon="mdi:pencil" width={16} />
            </IconButton>
          </Stack>
        </Stack>
      )}

      {formShown && (
        <Stack direction="column" spacing={1} alignItems={alignItems} width={1}>
          <Stack direction="row" justifyContent={alignItems} alignItems="center" spacing={1}>
            {!activeAddressLabel && (
              <>
                <Iconify icon="mage:exclamation-triangle-fill" color="warning" width={16} />
                <Typography variant="body2" color="textSecondary">
                  Please specify a location, so we can search for projects near you.
                </Typography>
              </>
            )}

            {activeAddressLabel && (
              <Typography variant="body2" color="textSecondary">
                Enter a new location to search for projects in that area.
              </Typography>
            )}
          </Stack>

          <LocationInput initialValue="" onSelect={onChangeAddress} />
        </Stack>
      )}
    </Stack>
  );
}

UserLocationForm.propTypes = {
  activeAddressLabel: PropTypes.string,
  formShown: PropTypes.bool,
  toggleForm: PropTypes.func,
  onChangeAddress: PropTypes.func,
  alignItems: PropTypes.any,
  textAlign: PropTypes.any,
};
