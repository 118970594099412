import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const HOST_API = import.meta.env.VITE_HOST_API;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

export const AUTH0_API = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
  scope: import.meta.env.VITE_AUTH0_SCOPE,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
};

export const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
