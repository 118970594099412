import { useMemo } from 'react';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useUser } from 'src/hooks/use-user';
import { useScopes } from 'src/hooks/use-scopes';
import { useUsersInvites } from 'src/hooks/use-users-invites';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

function generateInviteNudging(invites) {
  const total = invites.filter((v) => !v.validFrom || new Date(v.validFrom) < new Date());
  const used = total.filter((v) => v.used);

  return total.length > 0 ? ` (${used.length}/${total.length})` : '';
}

export function useNavData() {
  const { t } = useTranslate();
  const params = useParams();
  const { hasOneOfScopes, hasScope } = useScopes();

  const { user: authUser } = useAuthContext();
  const { data: impersonationUser } = useUser(params?.sub);

  const { data: user } = useUser();

  const { data: invites } = useUsersInvites(authUser?.sub);
  const { data: impersonationInvites } = useUsersInvites(params?.sub);

  const ownInvitesNudging = useMemo(
    () => generateInviteNudging(invites?.invitationCodes || []),
    [invites?.invitationCodes]
  );

  const impersonationInvitesNudging = useMemo(
    () => generateInviteNudging(impersonationInvites?.invitationCodes || []),
    [impersonationInvites?.invitationCodes]
  );

  const data = useMemo(
    () =>
      [
        // MANAGEMENT OWN USER
        // ----------------------------------------------------------------------
        {
          subheader: user?.full_name ? user.full_name : t('management'),
          items: [
            {
              title: t('projects'),
              path: paths.dashboard.management.board(),
              altPaths: [paths.dashboard.management.group('')],
              icon: ICONS.file,
            },
            {
              title: t('profile'),
              path: paths.dashboard.management.profile(user?.sub || authUser?.sub),
              icon: ICONS.user,
            },
            {
              title: t('interests'),
              path: paths.dashboard.management.subscriptions(user?.sub || authUser?.sub),
              icon: ICONS.mail,
            },
            {
              title: t('billing'),
              path: paths.dashboard.management.billing(user?.sub || authUser?.sub),
              icon: ICONS.invoice,
            },
            {
              title: `${t('invite a friend')}${ownInvitesNudging}`,
              path: paths.dashboard.management.invites(user?.sub || authUser?.sub),
              icon: ICONS.lock,
            },
            hasScope('admin:users')
              ? {
                  title: t('(projects old)'),
                  path: paths.dashboard.management.projects(user?.sub || authUser?.sub),
                  icon: ICONS.file,
                }
              : null,
          ].filter((v) => v !== null),
        },

        // MANAGEMENT IMPERSONATION
        // ----------------------------------------------------------------------
        impersonationUser?.sub !== user?.sub
          ? {
              subheader: impersonationUser?.full_name
                ? impersonationUser?.full_name
                : t('management'),
              items: [
                {
                  title: t('projects'),
                  path: paths.dashboard.management.board(impersonationUser?.sub),
                  icon: ICONS.file,
                },
                {
                  title: t('profile'),
                  path: paths.dashboard.management.profile(impersonationUser?.sub),
                  icon: ICONS.user,
                },
                {
                  title: t('interests'),
                  path: paths.dashboard.management.subscriptions(impersonationUser?.sub),
                  icon: ICONS.mail,
                },
                {
                  title: t('(projects old)'),
                  path: paths.dashboard.management.projects(impersonationUser?.sub),
                  icon: ICONS.file,
                },
                {
                  title: `${t('invite a friend')}${impersonationInvitesNudging}`,
                  path: paths.dashboard.management.invites(impersonationUser?.sub),
                  icon: ICONS.lock,
                },
              ],
            }
          : null,

        // ADMINISTRATION
        // ----------------------------------------------------------------------
        hasOneOfScopes(['read:users', 'write:prompts', 'write:project-providers'])
          ? {
              subheader: t('administration'),
              items: [
                hasScope('read:statistics')
                  ? {
                      title: t('statistics'),
                      path: paths.dashboard.admin.statistics,
                      icon: ICONS.analytics,
                    }
                  : null,
                hasScope('read:statistics')
                  ? {
                      title: t('action events'),
                      path: paths.dashboard.admin.actionEvents,
                      icon: ICONS.dashboard,
                    }
                  : null,
                hasScope('read:users')
                  ? {
                      title: t('users'),
                      path: paths.dashboard.admin.users,
                      icon: ICONS.user,
                    }
                  : null,
                hasScope('read:users')
                  ? {
                      title: t('waiting list'),
                      path: paths.dashboard.admin.waitingList,
                      icon: ICONS.tour,
                    }
                  : null,
                hasScope('write:prompts')
                  ? {
                      title: t('prompts'),
                      path: paths.dashboard.admin.prompts.list,
                      icon: ICONS.chat,
                      children: hasScope('write:prompts')
                        ? [
                            {
                              title: t('list'),
                              path: paths.dashboard.admin.prompts.list,
                            },
                            ...(hasScope('write:prompts')
                              ? [
                                  {
                                    title: t('Create'),
                                    path: paths.dashboard.admin.prompts.new,
                                  },
                                ]
                              : []),
                            ...(hasScope('optimize:prompts')
                              ? [
                                  {
                                    title: t('Optimize'),
                                    path: paths.dashboard.admin.prompts.optimize,
                                  },
                                  {
                                    title: t('Benchmarks'),
                                    path: paths.dashboard.admin.prompts.benchmarks,
                                  },
                                ]
                              : []),
                          ]
                        : undefined,
                    }
                  : null,
                hasScope('write:project-providers')
                  ? {
                      title: t('project providers'),
                      path: paths.dashboard.admin.projectProviders.list,
                      icon: ICONS.job,
                      children: hasScope('write:project-providers')
                        ? [
                            {
                              title: t('list'),
                              path: paths.dashboard.admin.projectProviders.list,
                            },
                            {
                              title: t('Create'),
                              path: paths.dashboard.admin.projectProviders.new,
                            },
                          ]
                        : undefined,
                    }
                  : null,
                hasScope('admin:users')
                  ? {
                      title: t('settings'),
                      path: paths.dashboard.admin.settings,
                      icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
                    }
                  : null,
                hasScope('admin:users')
                  ? {
                      title: t('crawler'),
                      path: paths.dashboard.admin.crawler.crawl,
                      icon: ICONS.external,
                    }
                  : null,
              ].filter((v) => v !== null),
            }
          : null,
      ].filter((v) => v !== null),
    [
      t,
      user?.full_name,
      user?.sub,
      authUser?.sub,
      ownInvitesNudging,
      impersonationUser?.sub,
      impersonationUser?.full_name,
      impersonationInvitesNudging,
      hasOneOfScopes,
      hasScope,
    ]
  );

  return data;
}
