import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';

import {
  Card,
  Stack,
  Table,
  Button,
  Portal,
  Tooltip,
  Divider,
  Backdrop,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  Pagination,
  TableContainer,
  TableSortLabel,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useScopes } from 'src/hooks/use-scopes';
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';

// ----------------------------------------------------------------------

const APP_LOG_VIEWER_ORDER = 'app-log-viewer-order';

const ZINDEX = 1998;
const POSITION = 24;

// ----------------------------------------------------------------------

export function AppLogViewer({ dependencies }) {
  const { hasScope } = useScopes();
  const { enqueueSnackbar } = useSnackbar();
  const logsShown = useBoolean(false);
  const orderAsc = useBoolean(localStorage.getItem(APP_LOG_VIEWER_ORDER) === 'asc');
  const [activeDependencies, setActiveDependencies] = useState(Object.keys(dependencies));

  useEffect(() => {
    localStorage.setItem(APP_LOG_VIEWER_ORDER, orderAsc.value ? 'asc' : 'desc');
  }, [orderAsc.value]);

  /**
   * Data Fetching
   */
  const api = useApi();
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(null);
  const [logs, setLogs] = useState([]);

  const loadLogs = useCallback(async () => {
    if (activeDependencies.length === 0) {
      setLogs([]);
      setMaxPages(0);
      return;
    }

    try {
      const result = await api.get('/v1/logs', {
        params: {
          from: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: orderAsc.value ? 'asc' : 'desc',
          ...activeDependencies.reduce((acc, key) => {
            acc[key] = dependencies[key];
            return acc;
          }, {}),
        },
      });

      setLogs(result.data.data);
      setMaxPages(Math.ceil((result.data?.metadata?.[0]?.total || 0) / itemsPerPage));
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar('Failed to fetch logs', { variant: 'error' });
      console.error(error);
    }
  }, [activeDependencies, api, currentPage, dependencies, orderAsc.value, enqueueSnackbar]);

  useEffect(() => {
    if (!logsShown.value) {
      return;
    }

    loadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, activeDependencies, dependencies, orderAsc.value, logsShown.value]);

  // bind key listener to ESCAPE to close the logShown var
  useEffect(() => {
    if (!logsShown.value) {
      return () => {};
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        logsShown.onFalse();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [logsShown]);

  if (!hasScope('admin:users')) {
    return null;
  }

  return (
    <>
      {logsShown.value && (
        <Portal>
          <Backdrop open sx={{ zIndex: ZINDEX }} />

          <Card
            sx={{
              borderRadius: 2,
              display: 'flex',
              position: 'fixed',
              zIndex: ZINDEX + 1,
              flexDirection: 'column',
              boxShadow: (theme) => theme.customShadows.dropdown,
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
              overflow: 'hidden',
            }}
          >
            <Stack direction="column" spacing={3} sx={{ overflow: 'scroll' }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  bgcolor: 'background.neutral',
                  p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Application Logs
                </Typography>

                <IconButton onClick={logsShown.onFalse}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  px: 2,
                }}
                flexWrap="wrap"
              >
                {Object.keys(dependencies)
                  .filter((v) => !!dependencies[v])
                  .map((key) => (
                    <Button
                      key={key}
                      variant="contained"
                      color={activeDependencies.includes(key) ? 'primary' : 'inherit'}
                      onClick={() => {
                        if (activeDependencies.includes(key)) {
                          setActiveDependencies(activeDependencies.filter((v) => v !== key));
                        } else {
                          setActiveDependencies([...activeDependencies, key]);
                        }
                      }}
                    >
                      <span>
                        {key}: {dependencies[key]}
                      </span>
                    </Button>
                  ))}
              </Stack>

              {activeDependencies.length === 0 && (
                <Typography variant="body1" sx={{ p: 2 }}>
                  No logs found for the selected filters.
                </Typography>
              )}

              <TableContainer>
                <Scrollbar>
                  <Table>
                    <TableHeadCustom
                      headLabel={[
                        {
                          id: 'createdAt',
                          label: (
                            <TableSortLabel
                              hideSortIcon
                              active
                              direction={orderAsc.value ? 'asc' : 'desc'}
                              onClick={orderAsc.onToggle}
                            >
                              Date
                            </TableSortLabel>
                          ),
                        },
                        { id: 'message', label: 'Message' },
                      ]}
                    />

                    <TableBody>
                      {(logs || []).map((log) => (
                        <TableRow key={log._id}>
                          <TableCell sx={{ width: '175px' }}>
                            {new Date(log.loggedAt).toLocaleString()}
                          </TableCell>

                          <TableCell>
                            <Stack direction="column" spacing={1}>
                              <Typography variant="body2">{log.message}</Typography>

                              <Stack direction="row" spacing={0.5} flexWrap="wrap">
                                {Object.keys(log.dependencies || {}).map((depKey) => (
                                  <Button
                                    size="small"
                                    key={depKey}
                                    variant="soft"
                                    sx={{ fontWeight: '400' }}
                                  >
                                    <span>
                                      {depKey}: {log.dependencies[depKey]}
                                    </span>
                                  </Button>
                                ))}
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {maxPages > 1 && (
                    <>
                      <Divider />
                      <Stack sx={{ p: 1, width: 1 }} alignItems="center">
                        <Pagination
                          count={maxPages}
                          page={currentPage}
                          siblingCount={0}
                          boundaryCount={1}
                          onChange={(_, newValue) => {
                            setCurrentPage(newValue);
                          }}
                        />
                      </Stack>
                    </>
                  )}
                </Scrollbar>
              </TableContainer>
            </Stack>
          </Card>
        </Portal>
      )}

      <Tooltip placement="top" arrow title="Show application logs">
        <IconButton onClick={logsShown.onToggle}>
          <Iconify icon="solar:document-text-bold" width={24} />
        </IconButton>
      </Tooltip>
    </>
  );
}

AppLogViewer.propTypes = {
  dependencies: PropTypes.shape({
    userId: PropTypes.string,
    matchingId: PropTypes.string,
    projectId: PropTypes.string,
    projectGroupId: PropTypes.string,
  }),
};
