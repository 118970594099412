import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function OptionalTooltip({ show, title, children }) {
  if (!show) {
    return children;
  }

  return (
    <Tooltip arrow title={title}>
      <span>{children}</span>
    </Tooltip>
  );
}

OptionalTooltip.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};
