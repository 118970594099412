import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import EnvironmentBanner from 'src/components/environment-banner/environment-banner';

import Header from '../common/header-simple';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  '@supports(height: 100dvh)': {
    height: '100dvh',
  },
}));

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  return (
    <>
      <EnvironmentBanner />

      <Header />

      <StyledRoot>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Container component="main">
          <Stack
            sx={{
              m: 'auto',
              maxWidth: 400,
              minHeight: '100vh',
              textAlign: 'center',
              justifyContent: 'center',
              '@supports(min-height: 100dvh)': {
                minHeight: '100dvh',
              },
            }}
          >
            {children}
          </Stack>
        </Container>
      </StyledRoot>
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
