import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Navigate, useParams } from 'react-router-dom';

import { useUser } from 'src/hooks/use-user';

import { useAuthContext } from 'src/auth/hooks';

import { LoadingScreen } from 'src/components/loading-screen';

export default function InternalUserAware({ children }) {
  const { user: authUser } = useAuthContext();
  const { sub: userSub } = useParams();
  const { data: user, isLoading } = useUser(userSub);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!user) {
    Sentry.setContext('authUser', JSON.stringify(authUser));
    Sentry.captureException(new Error('InternalUserAware: User not found'));
    return <Navigate replace to="/logout" />;
  }

  return children;
}

InternalUserAware.propTypes = {
  children: PropTypes.node.isRequired,
};
