import { Helmet } from 'react-helmet-async';

import EmailConfirmView from 'src/pages/email-confirm/email-confirm-view';

// ----------------------------------------------------------------------

export default function EmailConfirm() {
  return (
    <>
      <Helmet>
        <title>projectfit.ai | Confirm your email</title>
      </Helmet>

      <EmailConfirmView />
    </>
  );
}
