import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/react';

import { AUTH0_API } from 'src/config-global';

const STORE_PREFIX = '@@auth0spajs@@';
const STORE_SUFFIX = '@@user@@';

export async function auth0FakeLogin(username, password) {
  const domain = AUTH0_API.domain ?? '';
  const clientId = AUTH0_API.clientId ?? '';
  const audience = AUTH0_API.audience ?? '';
  const scope = `${AUTH0_API.scope} offline_access` ?? '';

  try {
    const response = await fetch(`https://${domain}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'password',
        username,
        password,
        audience,
        realm: 'regular',
        scope,
        client_id: clientId,
      }),
    });

    const authData = await response.json();
    const idToken = authData?.id_token;
    const idTokenHeader = idToken?.split('.')[0];
    const idTokenPayload = idToken?.split('.')[1];
    const idTokenSignature = idToken?.split('.')[2];

    if (!authData || !idToken || !idTokenHeader || !idTokenPayload || !idTokenSignature) {
      console.log({ authData, idToken, idTokenHeader, idTokenPayload, idTokenSignature });
      throw new Error('Could not create login session for user.');
    }

    const decodedHeader = jwtDecode(idToken, { header: true });
    const decodedPayload = jwtDecode(idToken);

    /**
      "access_token": string,
      "id_token": string,
      "scope": string,
      "expires_in": number,
      "token_type": string
     */

    document.cookie = `auth0.${clientId}.is.authenticated=true; max-age=${authData.expires_in}; SameSite=None; Secure; Path=/`;
    document.cookie = `_legacy.auth0.${clientId}.is.authenticated=true; max-age=${authData.expires_in}; SameSite=None; Secure; Path=/`;

    localStorage.setItem(
      [STORE_PREFIX, clientId, audience, scope].join('::'),
      JSON.stringify({
        body: {
          ...authData,
          audience,
          client_id: clientId,
          oauthTokenScope: authData.scope,
        },
        expiresAt: Math.round(new Date().getTime() / 1000) + (authData.expires_in ?? 86400),
      })
    );

    localStorage.setItem(
      [STORE_PREFIX, clientId, STORE_SUFFIX].join('::'),
      JSON.stringify({
        id_token: idToken,
        decodedToken: {
          encoded: {
            header: idTokenHeader,
            payload: idTokenPayload,
            signature: idTokenSignature,
          },
          header: decodedHeader,
          claims: {
            __raw: idToken,
            ...decodedPayload,
          },
          user: decodedPayload,
        },
      })
    );
  } catch (error) {
    console.error(`Authentication error: ${error.message}`);
    Sentry.captureException(error);
    throw error;
  }
}
