import PropTypes from 'prop-types';

import { Alert } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { useLocalStorage } from 'src/hooks/use-local-storage';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'group-info-duplicates-info';

// ----------------------------------------------------------------------

export default function GroupInfoDuplicatesInfo({ item, user }) {
  const { state: hideInfo, update } = useLocalStorage(STORAGE_KEY, false);

  const theme = useTheme();

  if (item?.postingCount <= 1 || hideInfo) {
    return null;
  }

  return (
    <Alert
      severity="success"
      onClose={() => {
        update(true);
      }}
      closeText="Do not show this message again"
      sx={{
        ...bgGradient({
          direction: '45deg',
          startColor: alpha(theme.palette.success.light, 0.2),
          endColor: alpha(theme.palette.success.main, 0.25),
        }),
      }}
    >
      <b>
        Great news! We&apos;ve identified this project on multiple platforms and consolidated them
        for you.
      </b>{' '}
      Now, you can easily choose the platform and agency you&apos;re most comfortbale with, saving
      you time and effort!
    </Alert>
  );
}

GroupInfoDuplicatesInfo.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
