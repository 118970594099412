export const CONTRACT_TYPES = [
  {
    value: 'contractor',
    label: 'Contractor',
  },
  { value: 'temporary-employment', label: 'Temporary Employment' },
  { value: 'employment', label: 'Employment' },
  { value: 'other', label: 'Other' },
];

export default function contractTypeLabel(value) {
  const item = CONTRACT_TYPES.find((i) => i.value === value);

  return item ? item.label : 'Unknown';
}
