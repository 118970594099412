import * as jose from 'jose';
import { useState, useEffect } from 'react';

import { useAuthContext } from 'src/auth/hooks';

export function useScopes() {
  const { getAccessTokenSilently } = useAuthContext();
  const [scopes, setScopes] = useState([]);

  const hasOneOfScopes = (requiredScopes) =>
    requiredScopes.some((requiredScope) => (scopes || []).includes(requiredScope));

  const hasAllScopes = (requiredScopes) =>
    requiredScopes.every((requiredScope) => (scopes || []).includes(requiredScope));

  const hasScope = (requiredScope) => (scopes || []).includes(requiredScope);

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => jose.decodeJwt(token))
      .then((payload) => setScopes(payload.scope.split(' ')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { scopes, hasOneOfScopes, hasAllScopes, hasScope };
}
