import { Helmet } from 'react-helmet-async';

import { Button } from '@mui/material';
import { Stack, Container } from '@mui/system';

// ----------------------------------------------------------------------

export default function ImprintPage() {
  /* eslint-disable prettier/prettier */
  return (
    <>
      <Helmet>
        <title>projectfit.ai | Privacy Policy</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          sx={{
            maxWidth: 720,
            mx: 'auto',
            mt: { xs: 5, md: 10 },
          }}
        >
          <h1>Privacy Policy</h1>
          <h2>1. An overview of data protection</h2>
          <h3>General information</h3>{' '}
          <p>
            The following information will provide you with an easy to navigate overview of what
            will happen with your personal data when you visit this website. The term
            &ldquo;personal data&rdquo; comprises all data that can be used to personally identify
            you. For detailed information about the subject matter of data protection, please
            consult our Data Protection Declaration, which we have included beneath this copy.
          </p>
          <h3>Data recording on this website</h3>{' '}
          <h4>
            Who is the responsible party for the recording of data on this website (i.e., the
            &ldquo;controller&rdquo;)?
          </h4>{' '}
          <p>
            The data on this website is processed by the operator of the website, whose contact
            information is available under section &ldquo;Information about the responsible party
            (referred to as the &ldquo;controller&rdquo; in the GDPR)&rdquo; in this Privacy Policy.
          </p>{' '}
          <h4>How do we record your data?</h4>{' '}
          <p>
            We collect your data as a result of your sharing of your data with us. This may, for
            instance be information you enter into our contact form.
          </p>{' '}
          <p>
            Other data shall be recorded by our IT systems automatically or after you consent to its
            recording during your website visit. This data comprises primarily technical information
            (e.g., web browser, operating system, or time the site was accessed). This information
            is recorded automatically when you access this website.
          </p>{' '}
          <h4>What are the purposes we use your data for?</h4>{' '}
          <p>
            A portion of the information is generated to guarantee the error free provision of the
            website. Other data may be used to analyze your user patterns.
          </p>{' '}
          <h4>What rights do you have as far as your information is concerned?</h4>{' '}
          <p>
            You have the right to receive information about the source, recipients, and purposes of
            your archived personal data at any time without having to pay a fee for such
            disclosures. You also have the right to demand that your data are rectified or
            eradicated. If you have consented to data processing, you have the option to revoke this
            consent at any time, which shall affect all future data processing. Moreover, you have
            the right to demand that the processing of your data be restricted under certain
            circumstances. Furthermore, you have the right to log a complaint with the competent
            supervising agency.
          </p>{' '}
          <p>
            Please do not hesitate to contact us at any time if you have questions about this or any
            other data protection related issues.
          </p>
          <h3>Analysis tools and tools provided by third parties</h3>{' '}
          <p>
            There is a possibility that your browsing patterns will be statistically analyzed when
            your visit this website. Such analyses are performed primarily with what we refer to as
            analysis programs.
          </p>{' '}
          <p>
            For detailed information about these analysis programs please consult our Data
            Protection Declaration below.
          </p>
          <h2>2. Hosting and Content Delivery Networks (CDN)</h2>
          <p>We are hosting the content of our website at the following provider:</p>
          <h3>Amazon Web Services (AWS)</h3>{' '}
          <p>
            The provider is the Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855
            Luxembourg (hereinafter referred to as &ldquo;AWS&rdquo;).
          </p>{' '}
          <p>
            When you visit our website, your personal data will be processed on AWS servers. This
            may also result in the transfer of personal data to the parent company of AWS in the
            United States. The transfer of data to the US is based on the EU&rsquo;s standard
            contractual clauses. For details please consult:{' '}
            <a
              href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </p>{' '}
          <p>
            For more information, please see the AWS Data Privacy Policy:{' '}
            <a
              href="https://aws.amazon.com/de/privacy/?nc1=f_pr"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://aws.amazon.com/de/privacy/?nc1=f_pr
            </a>
            .
          </p>{' '}
          <p>
            AWS is used on the basis of Art. 6(1)(f) GDPR. We have a legitimate interest in a
            depiction of our website that is as reliable as possible. If appropriate consent has
            been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a)
            GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the storage of cookies or the
            access to information in the user&rsquo;s end device (e.g., device fingerprinting)
            within the meaning of the TTDSG. This consent can be revoked at any time.
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
            </a>
          </p>
          <h3>Amazon CloudFront CDN</h3>{' '}
          <p>
            We use the Content Delivery Network Amazon CloudFront CDN. The provider is Amazon Web
            Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxembourg (hereinafter referred
            to as &ldquo;Amazon&rdquo;).
          </p>{' '}
          <p>
            Amazon CloudFront CDN is a globally distributed Content Delivery Network. During these
            transactions, the information transfer between your browser and our website is
            technically routed via the Content Delivery Network. This enables us to boost the global
            availability and performance capabilities of our website.
          </p>{' '}
          <p>
            The use of Amazon CloudFront CDN is based on our legitimate interest in keeping the
            presentation of our web services as error free and secure as possible (Art. 6(1)(f)
            GDPR).
          </p>{' '}
          <p>
            The data transfer to the United States is based on the Standard Contract Clauses of the
            EU Commission.&nbsp; You can find the details here:{' '}
            <a
              href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </p>{' '}
          <p>
            For more information on Amazon CloudFront CDN please follow this link:{' '}
            <a
              href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
            </a>
            .
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
            </a>
          </p>
          <h2>3. General information and mandatory information</h2>
          <h3>Data protection</h3>{' '}
          <p>
            The operators of this website and its pages take the protection of your personal data
            very seriously. Hence, we handle your personal data as confidential information and in
            compliance with the statutory data protection regulations and this Data Protection
            Declaration.
          </p>{' '}
          <p>
            Whenever you use this website, a variety of personal information will be collected.
            Personal data comprises data that can be used to personally identify you. This Data
            Protection Declaration explains which data we collect as well as the purposes we use
            this data for. It also explains how, and for which purpose the information is collected.
          </p>{' '}
          <p>
            We herewith advise you that the transmission of data via the Internet (i.e., through
            e-mail communications) may be prone to security gaps. It is not possible to completely
            protect data against third-party access.
          </p>
          <h3>
            Information about the responsible party (referred to as the &ldquo;controller&rdquo; in
            the GDPR)
          </h3>{' '}
          <p>The data processing controller on this website is:</p>{' '}
          <p>
            Christian Schuhbaum
            <br />
            Waldmeisterstr. 31
            <br />
            80935 M&uuml;nchen
            <br />
            Deutschland
          </p>
          <p>
            Phone: +49 151 194 25 530
            <br />
            E-mail: info@projectfit.ai
          </p>
          <p>
            The controller is the natural person or legal entity that single-handedly or jointly
            with others makes decisions as to the purposes of and resources for the processing of
            personal data (e.g., names, e-mail addresses, etc.).
          </p>
          <h3>Storage duration</h3>{' '}
          <p>
            Unless a more specific storage period has been specified in this privacy policy, your
            personal data will remain with us until the purpose for which it was collected no longer
            applies. If you assert a justified request for deletion or revoke your consent to data
            processing, your data will be deleted, unless we have other legally permissible reasons
            for storing your personal data (e.g., tax or commercial law retention periods); in the
            latter case, the deletion will take place after these reasons cease to apply.
          </p>
          <h3>General information on the legal basis for the data processing on this website</h3>{' '}
          <p>
            If you have consented to data processing, we process your personal data on the basis of
            Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed
            according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of
            personal data to third countries, the data processing is also based on Art. 49 (1)(a)
            GDPR. If you have consented to the storage of cookies or to the access to information in
            your end device (e.g., via device fingerprinting), the data processing is additionally
            based on &sect; 25 (1) TTDSG. The consent can be revoked at any time. If your data is
            required for the fulfillment of a contract or for the implementation of pre-contractual
            measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your
            data is required for the fulfillment of a legal obligation, we process it on the basis
            of Art. 6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis
            of our legitimate interest according to Art. 6(1)(f) GDPR. Information on the relevant
            legal basis in each individual case is provided in the following paragraphs of this
            privacy policy.
          </p>
          <h3>
            Information on the data transfer to third-party countries that are not secure under data
            protection law and the transfer to US companies that are not DPF-certified
          </h3>{' '}
          <p>
            We use, among other technologies, tools from companies located in third-party countries
            that are not safe under data protection law, as well as US tools whose providers are not
            certified under the EU-US Data Privacy Framework (DPF). If these tools are enabled, your
            personal data may be transferred to and processed in these countries. We would like you
            to note that no level of data protection comparable to that in the EU can be guaranteed
            in third countries that are insecure in terms of data protection law.
          </p>{' '}
          <p>
            We would like to point out that the US, as a secure third-party country, generally has a
            level of data protection comparable to that of the EU. Data transfer to the US is
            therefore permitted if the recipient is certified under the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF) or has appropriate additional assurances. Information on
            transfers to third-party countries, including the data recipients, can be found in this
            Privacy Policy.
          </p>
          <h3>Recipients of personal data</h3>{' '}
          <p>
            In the scope of our business activities, we cooperate with various external parties. In
            some cases, this also requires the transfer of personal data to these external parties.
            We only disclose personal data to external parties if this is required as part of the
            fulfillment of a contract, if we are legally obligated to do so (e.g., disclosure of
            data to tax authorities), if we have a legitimate interest in the disclosure pursuant to
            Art. 6 (1)(f) GDPR, or if another legal basis permits the disclosure of this data. When
            using processors, we only disclose personal data of our customers on the basis of a
            valid contract on data processing. In the case of joint processing, a joint processing
            agreement is concluded.
          </p>
          <h3>Revocation of your consent to the processing of data</h3>{' '}
          <p>
            A wide range of data processing transactions are possible only subject to your express
            consent. You can also revoke at any time any consent you have already given us. This
            shall be without prejudice to the lawfulness of any data collection that occurred prior
            to your revocation.
          </p>
          <h3>
            Right to object to the collection of data in special cases; right to object to direct
            advertising (Art. 21 GDPR)
          </h3>{' '}
          <p>
            IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE
            THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS
            ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
            PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED,
            PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO
            LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT
            COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR
            INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING,
            EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
          </p>{' '}
          <p>
            IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU
            HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE
            PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT
            THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA
            WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT
            TO ART. 21(2) GDPR).
          </p>
          <h3>Right to log a complaint with the competent supervisory agency</h3>{' '}
          <p>
            In the event of violations of the GDPR, data subjects are entitled to log a complaint
            with a supervisory agency, in particular in the member state where they usually maintain
            their domicile, place of work or at the place where the alleged violation occurred. The
            right to log a complaint is in effect regardless of any other administrative or court
            proceedings available as legal recourses.
          </p>
          <h3>Right to data portability</h3>{' '}
          <p>
            You have the right to have data that we process automatically on the basis of your
            consent or in fulfillment of a contract handed over to you or to a third party in a
            common, machine-readable format. If you should demand the direct transfer of the data to
            another controller, this will be done only if it is technically feasible.
          </p>
          <h3>Information about, rectification and eradication of data</h3>{' '}
          <p>
            Within the scope of the applicable statutory provisions, you have the right to demand
            information about your archived personal data, their source and recipients as well as
            the purpose of the processing of your data at any time. You may also have a right to
            have your data rectified or eradicated. If you have questions about this subject matter
            or any other questions about personal data, please do not hesitate to contact us at any
            time.
          </p>
          <h3>Right to demand processing restrictions</h3>{' '}
          <p>
            You have the right to demand the imposition of restrictions as far as the processing of
            your personal data is concerned. To do so, you may contact us at any time. The right to
            demand restriction of processing applies in the following cases:
          </p>{' '}
          <ul>
            {' '}
            <li>
              In the event that you should dispute the correctness of your data archived by us, we
              will usually need some time to verify this claim. During the time that this
              investigation is ongoing, you have the right to demand that we restrict the processing
              of your personal data.
            </li>{' '}
            <li>
              If the processing of your personal data was/is conducted in an unlawful manner, you
              have the option to demand the restriction of the processing of your data instead of
              demanding the eradication of this data.
            </li>{' '}
            <li>
              If we do not need your personal data any longer and you need it to exercise, defend or
              claim legal entitlements, you have the right to demand the restriction of the
              processing of your personal data instead of its eradication.
            </li>{' '}
            <li>
              If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our
              rights will have to be weighed against each other. As long as it has not been
              determined whose interests prevail, you have the right to demand a restriction of the
              processing of your personal data.
            </li>{' '}
          </ul>{' '}
          <p>
            If you have restricted the processing of your personal data, these data &ndash; with the
            exception of their archiving &ndash; may be processed only subject to your consent or to
            claim, exercise or defend legal entitlements or to protect the rights of other natural
            persons or legal entities or for important public interest reasons cited by the European
            Union or a member state of the EU.
          </p>
          <h3>SSL and/or TLS encryption</h3>{' '}
          <p>
            For security reasons and to protect the transmission of confidential content, such as
            purchase orders or inquiries you submit to us as the website operator, this website uses
            either an SSL or a TLS encryption program. You can recognize an encrypted connection by
            checking whether the address line of the browser switches from &ldquo;http://&rdquo; to
            &ldquo;https://&rdquo; and also by the appearance of the lock icon in the browser line.
          </p>{' '}
          <p>
            If the SSL or TLS encryption is activated, data you transmit to us cannot be read by
            third parties.
          </p>
          <h2>4. Recording of data on this website</h2>
          <h3>Cookies</h3>{' '}
          <p>
            Our websites and pages use what the industry refers to as &ldquo;cookies.&rdquo; Cookies
            are small data packages that do not cause any damage to your device. They are either
            stored temporarily for the duration of a session (session cookies) or they are
            permanently archived on your device (permanent cookies). Session cookies are
            automatically deleted once you terminate your visit. Permanent cookies remain archived
            on your device until you actively delete them, or they are automatically eradicated by
            your web browser.
          </p>{' '}
          <p>
            Cookies can be issued by us (first-party cookies) or by third-party companies (so-called
            third-party cookies). Third-party cookies enable the integration of certain services of
            third-party companies into websites (e.g., cookies for handling payment services).
          </p>{' '}
          <p>
            Cookies have a variety of functions. Many cookies are technically essential since
            certain website functions would not work in the absence of these cookies (e.g., the
            shopping cart function or the display of videos). Other cookies may be used to analyze
            user behavior or for promotional purposes.
          </p>{' '}
          <p>
            Cookies, which are required for the performance of electronic communication
            transactions, for the provision of certain functions you want to use (e.g., for the
            shopping cart function) or those that are necessary for the optimization (required
            cookies) of the website (e.g., cookies that provide measurable insights into the web
            audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal
            basis is cited. The operator of the website has a legitimate interest in the storage of
            required cookies to ensure the technically error-free and optimized provision of the
            operator&rsquo;s services. If your consent to the storage of the cookies and similar
            recognition technologies has been requested, the processing occurs exclusively on the
            basis of the consent obtained (Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG); this consent
            may be revoked at any time.
          </p>{' '}
          <p>
            You have the option to set up your browser in such a manner that you will be notified
            any time cookies are placed and to permit the acceptance of cookies only in specific
            cases. You may also exclude the acceptance of cookies in certain cases or in general or
            activate the delete-function for the automatic eradication of cookies when the browser
            closes. If cookies are deactivated, the functions of this website may be limited.
          </p>{' '}
          <p>
            <Button variant="contained" color="inherit" data-cc="show-preferencesModal">
              Manage cookie preferences
            </Button>
          </p>
          <h3>Registration on this website</h3>{' '}
          <p>
            You have the option to register on this website to be able to use additional website
            functions. We shall use the data you enter only for the purpose of using the respective
            offer or service you have registered for. The required information we request at the
            time of registration must be entered in full. Otherwise, we shall reject the
            registration.
          </p>{' '}
          <p>
            To notify you of any important changes to the scope of our portfolio or in the event of
            technical modifications, we shall use the e-mail address provided during the
            registration process.
          </p>{' '}
          <p>
            We shall process the data entered during the registration process on the basis of your
            consent (Art. 6(1)(a) GDPR).
          </p>{' '}
          <p>
            The data recorded during the registration process shall be stored by us as long as you
            are registered on this website. Subsequently, such data shall be deleted. This shall be
            without prejudice to mandatory statutory retention obligations.
          </p>
          <h2>5. Analysis tools and advertising</h2>
          <h3>Google Analytics</h3>{' '}
          <p>
            This website uses functions of the web analysis service Google Analytics. The provider
            of this service is Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow
            Street, Dublin 4, Ireland.
          </p>{' '}
          <p>
            Google Analytics enables the website operator to analyze the behavior patterns of
            website visitors. To that end, the website operator receives a variety of user data,
            such as pages accessed, time spent on the page, the utilized operating system and the
            user&rsquo;s origin. This data is assigned to the respective end device of the user. An
            assignment to a user-ID does not take place.
          </p>{' '}
          <p>
            Furthermore, Google Analytics allows us to record your mouse and scroll movements and
            clicks, among other things. Google Analytics uses various modeling approaches to augment
            the collected data sets and uses machine learning technologies in data analysis.
          </p>
          <p>
            Google Analytics uses technologies that make the recognition of the user for the purpose
            of analyzing the user behavior patterns (e.g., cookies or device fingerprinting). The
            website use information recorded by Google is, as a rule transferred to a Google server
            in the United States, where it is stored.
          </p>{' '}
          <p>
            The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a)
            GDPR and &sect; 25(1) TTDSG. You may revoke your consent at any time.
          </p>{' '}
          <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
            European Commission. Details can be found here:{' '}
            <a
              href="https://privacy.google.com/businesses/controllerterms/mccs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://privacy.google.com/businesses/controllerterms/mccs/
            </a>
            .
          </p>{' '}
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h4>IP anonymization</h4>{' '}
          <p>
            Google Analytics IP anonymization is active. As a result, your IP address will be
            abbreviated by Google within the member states of the European Union or in other states
            that have ratified the Convention on the European Economic Area prior to its
            transmission to the United States. The full IP address will be transmitted to one of
            Google&rsquo;s servers in the United States and abbreviated there only in exceptional
            cases. On behalf of the operator of this website, Google shall use this information to
            analyze your use of this website to generate reports on website activities and to render
            other services to the operator of this website that are related to the use of the
            website and the Internet. The IP address transmitted in conjunction with Google
            Analytics from your browser shall not be merged with other data in Google&rsquo;s
            possession.
          </p>
          <h4>Browser plug-in</h4>{' '}
          <p>
            You can prevent the recording and processing of your data by Google by downloading and
            installing the browser plugin available under the following link:{' '}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tools.google.com/dlpage/gaoptout?hl=en
            </a>
            .
          </p>{' '}
          <p>
            For more information about the handling of user data by Google Analytics, please consult
            Google&rsquo;s Data Privacy Declaration at:{' '}
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.google.com/analytics/answer/6004245?hl=en
            </a>
            .
          </p>
          <h4>Contract data processing</h4>{' '}
          <p>
            We have executed a contract data processing agreement with Google and are implementing
            the stringent provisions of the German data protection agencies to the fullest when
            using Google Analytics.
          </p>
          <h2>6. Plug-ins and Tools</h2>
          <h3>Sentry</h3>{' '}
          <p>
            We have integrated Sentry on this website. The provider is Functional Software Inc., 45
            Fremont Street, 8th Floor, San Francisco, California 94105, USA.
          </p>{' '}
          <p>
            Sentry is an open-source bug tracking service that allows us to monitor and fix bugs and
            crashes anywhere in a web-based software in real time.
          </p>{' '}
          <p>
            Sentry is used on the basis of Art. 6 (1)(f) GDPR. The website operator has a legitimate
            interest in the error-free functioning of its own website.
          </p>{' '}
          <p>
            If appropriate consent has been obtained, the processing is carried out exclusively on
            the basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the
            storage of cookies or the access to information in the user&rsquo;s end device (e.g.,
            device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at
            any time.
          </p>{' '}
          <p>
            For more details, please refer to the provider&apos;s privacy policy:{' '}
            <a href="https://sentry.io/privacy/" target="_blank" rel="noopener noreferrer">
              https://sentry.io/privacy/
            </a>
            .
          </p>{' '}
          <p>
            Data transfer to the US is based on the standard contractual clauses of the EU
            Commission. Details can be found here:
          </p>{' '}
          <p>
            <a
              href="https://sentry.io/legal/dpa/5.0.0/#cross-border-transfer-mechanisms"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://sentry.io/legal/dpa/5.0.0/#cross-border-transfer-mechanisms
            </a>{' '}
            und{' '}
            <a
              href="https://sentry.io/legal/dpa/5.0.0/#third-party"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://sentry.io/legal/dpa/5.0.0/#third-party
            </a>
            .
          </p>
          <p>
            The company is certified in accordance with the &ldquo;EU-US Data Privacy
            Framework&rdquo; (DPF). The DPF is an agreement between the European Union and the US,
            which is intended to ensure compliance with European data protection standards for data
            processing in the US. Every company certified under the DPF is obliged to comply with
            these data protection standards. For more information, please contact the provider under
            the following link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000YdenAAC&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000YdenAAC&status=Active
            </a>
          </p>
          <h4>Data processing</h4>{' '}
          <p>
            We have concluded a data processing agreement (DPA) for the use of the above-mentioned
            service. This is a contract mandated by data privacy laws that guarantees that they
            process personal data of our website visitors only based on our instructions and in
            compliance with the GDPR.
          </p>
          <h3>ChatGPT</h3>{' '}
          <p>
            We use ChatGPT for our customer communication. The provider is OpenAI, 3180 18th St, San
            Francisco, CA 94110, USA,{' '}
            <a href="https://openai.com" target="_blank" rel="noopener noreferrer">
              https://openai.com
            </a>
            . We use ChatGPT for the following tools:
          </p>{' '}
          <ul>
            <li>matching system to identify the matching level between a project and a user</li>
          </ul>{' '}
          <p>
            When you start a conversation with us via our website and ChatGPT is activated, your
            input (including metadata) is transferred to ChatGPT&apos;s servers and processed there
            to generate a suitable response.
          </p>{' '}
          <p>
            We have configured ChatGPT, so that the personal data entered will not be used to train
            ChatGPT&rsquo;s algorithm.
          </p>{' '}
          <p>
            The use of ChatGPT is based on Art. 6 (1)(f) GDPR. The website operator has a legitimate
            interest in the most efficient customer communication possible using modern technical
            solutions. If a corresponding consent has been requested, the processing is carried out
            exclusively on the basis of Art. 6 (1)(a) GDPR and &sect; 25 (1) TTDSG. The consent can
            be revoked at any time.
          </p>{' '}
          <p>
            You can obtain further information here:{' '}
            <a
              href="https://openai.com/policies/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://openai.com/policies/privacy-policy
            </a>
            .
          </p>
          <h4>Data processing</h4>{' '}
          <p>
            We have concluded a data processing agreement (DPA) for the use of the above-mentioned
            service. This is a contract mandated by data privacy laws that guarantees that they
            process personal data of our website visitors only based on our instructions and in
            compliance with the GDPR.
          </p>
          <h2>7. eCommerce and payment service providers</h2>
          <h3>Processing of Customer and Contract Data</h3>{' '}
          <p>
            We collect, process, and use personal customer and contract data for the establishment,
            content arrangement and modification of our contractual relationships. Data with
            personal references to the use of this website (usage data) will be collected,
            processed, and used only if this is necessary to enable the user to use our services or
            required for billing purposes. The legal basis for these processes is Art. 6(1)(b) GDPR.
          </p>{' '}
          <p>
            The collected customer data shall be deleted upon completion of the order or termination
            of the business relationship and upon expiration of any existing statutory archiving
            periods. This shall be without prejudice to any statutory archiving periods.
          </p>
          <h3>Data transfer upon closing of contracts for services and digital content</h3>{' '}
          <p>
            We share personal data with third parties only if this is necessary in conjunction with
            the handling of the contract; for instance, with the financial institution tasked with
            the processing of payments.
          </p>{' '}
          <p>
            Any further transfer of data shall not occur or shall only occur if you have expressly
            consented to the transfer. Any sharing of your data with third parties in the absence of
            your express consent, for instance for advertising purposes, shall not occur.
          </p>{' '}
          <p>
            The basis for the processing of data is Art. 6(1)(b) GDPR, which permits the processing
            of data for the fulfilment of a contract or for pre-contractual actions.
          </p>
          <h3>Payment services</h3>{' '}
          <p>
            We integrate payment services of third-party companies on our website. When you make a
            purchase from us, your payment data (e.g. name, payment amount, bank account details,
            credit card number) are processed by the payment service provider for the purpose of
            payment processing. For these transactions, the respective contractual and data
            protection provisions of the respective providers apply. The use of the payment service
            providers is based on Art. 6(1)(b) GDPR (contract processing) and in the interest of a
            smooth, convenient, and secure payment transaction (Art. 6(1)(f) GDPR). Insofar as your
            consent is requested for certain actions, Art. 6(1)(a) GDPR is the legal basis for data
            processing; consent may be revoked at any time for the future.
          </p>
          <p>
            We use the following payment services / payment service providers within the scope of
            this website:
          </p>
          <h4>Stripe</h4>{' '}
          <p>
            The provider for customers within the EU is Stripe Payments Europe, Ltd,1 Grand Canal
            Street Lower, Grand Canal Dock, Dublin, Ireland (hereinafter &ldquo;Stripe&rdquo;).
          </p>{' '}
          <p>
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the
            European Commission. Details can be found here:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>{' '}
            and{' '}
            <a
              href="https://stripe.com/de/guides/general-data-protection-regulation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/de/guides/general-data-protection-regulation
            </a>
            .
          </p>{' '}
          <p>
            Details can be found in Stripe&rsquo;s Privacy Policy at the following link:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>
            .
          </p>
          <h2>8. Single Sign-On (SSO) Introduction</h2>
          <p>
            At projectfit.ai, we streamline your online experience through the convenience of Single
            Sign-On (SSO) services. This feature allows you to register or sign in to our services
            using your existing information from a third-party service provider, such as LinkedIn or
            GitHub, enhancing the authentication process&apos;s simplicity and security.
          </p>
          <h3>Purpose of SSO</h3>
          <p>
            The purpose of integrating SSO is to simplify your login process, reducing the need to
            remember multiple passwords and enabling quicker access to our services. It saves you
            valuable time and increases security, as authentication is handled by providers you
            already trust.
          </p>
          <h3>Data Processed Through SSO</h3>
          <p>
            The exact data we receive via SSO depends on the third-party provider and may include
            your email address and username. We do not receive or store your passwords. For details
            on the specific data processed by each SSO tool, please refer to the respective privacy
            policies of the providers you choose to use.
          </p>
          <h3>Data Storage Duration</h3>
          <p>
            The duration of data storage depends on the tools used. For instance, social media
            platforms like LinkedIn may retain information until it&apos;s no longer required for
            their purposes. We only process personal data as long as necessary to provide our
            services.
          </p>
          <h3>Your Right to Object</h3>
          <p>
            You always have the right to withdraw your consent to the use of SSO services.
            Typically, this can be managed through the opt-out functions provided by the service
            providers. If available, our privacy policy may include direct links to these opt-out
            features.
          </p>
          <h3>Legal Basis</h3>
          <p>
            The use of SSO services is based on your consent (Article 6(1)(a) of the GDPR) and our
            legitimate interest in offering a convenient login process (Article 6(1)(f) of the
            GDPR). However, we only implement SSO services when you have granted explicit consent.
          </p>
          <p>
            If you wish to disconnect the link between your account and the SSO provider, please
            adjust the settings in your account with the respective provider. Should you wish to
            remove your data from our records as well, a formal request for account termination will
            be necessary.
          </p>
          <h3>Auth0 Single Sign-On Privacy Statement</h3>
          <p>
            We use Auth0 for SSO services on our website. Auth0 is provided by Auth0 Inc., located
            at 10800 NE 8th Street, Suite 700, Bellevue, WA 98004, USA.
          </p>
          <p>
            Please note that Auth0 may process data in the USA. Auth0 participates in the EU-US Data
            Privacy Framework, ensuring proper and secure data transfer of personal information from
            EU citizens to the USA. More information can be found at{' '}
            <a
              href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Auth0&apos;s EU-US Data Privacy Framework information page
            </a>
            .
          </p>
          <p>
            For more details on the data processed by using Auth0, please review their privacy
            policy under{' '}
            <a
              href="https://www.okta.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.okta.com/privacy-policy/
            </a>
            .
          </p>
        </Stack>
      </Container>
    </>
  );
}
