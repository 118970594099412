import { Box, Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function DocumentRowSkeleton() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ display: 'flex', width: 1 }}
    >
      <Skeleton variant="rectangular" width={24} height={24} />

      <Box sx={{ flexGrow: 1 }}>
        <Skeleton variant="rectangular" width="100%" height={24} />
      </Box>

      <Skeleton variant="circular" width={24} height={24} />
      <Skeleton variant="circular" width={24} height={24} />
    </Stack>
  );
}

DocumentRowSkeleton.propTypes = {};
