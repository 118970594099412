import { useUser } from 'src/hooks/use-user';

import { useAuthContext } from 'src/auth/hooks';

import { LoadingScreen } from 'src/components/loading-screen';

import OnboardingPage from 'src/sections/onboarding/onboarding';

// ----------------------------------------------------------------------

export default function Onboarding() {
  const { user: authUser } = useAuthContext();
  const { data: user, isLoading } = useUser();

  if (!authUser || !authUser?.sub) {
    return <LoadingScreen />;
  }

  if (isLoading || !user?._id) {
    return <LoadingScreen />;
  }

  return <OnboardingPage />;
}
