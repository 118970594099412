import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AUTH0
const CallbackPage = lazy(() => import('src/pages/auth/auth0/callback'));

// ----------------------------------------------------------------------

const authAuth0 = {
  path: 'auth0',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'callback',
      element: <CallbackPage />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authAuth0],
  },
];
