import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export function useProjectProviders(
  searchTerm = null,
  page = 0,
  pageSize = 100,
  sort = 'sequence',
  order = 'asc'
) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();
  const { data, error, isLoading, mutate } = useSWR(
    [
      `/v1/project-providers`,
      getAccessTokenSilently,
      enqueueSnackbar,
      {
        params: {
          ...(typeof page !== 'undefined' && typeof pageSize !== 'undefined'
            ? { from: page * pageSize, limit: pageSize }
            : {}),
          ...(typeof sort !== 'undefined' && typeof order !== 'undefined' ? { sort, order } : {}),
          ...(searchTerm ? { q: searchTerm } : {}),
        },
      },
    ],
    fetcher,
    swrConfig
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
