import { Box } from '@mui/material';

import { HEADER } from 'src/layouts/config-layout';

import RobertHero from 'src/sections/robert/robert-hero';

// ----------------------------------------------------------------------
export default function RobertView() {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url('/assets/background/overlay_7_gradient.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Box sx={{ pt: { xs: `${HEADER.H_MOBILE}px`, md: 0 } }}>
        <RobertHero />
      </Box>
    </>
  );
}
