export const E_MAIL_NOTIFICATION_OPTIONS = [
  {
    value: '0',
    label: 'Always',
    description: 'You will always receive all notifications',
  },
  {
    value: '33',
    label: 'Minimal Match',
    description: 'Receive notifications for at least minimal matches',
  },
  {
    value: '66',
    label: 'Good Match',
    description: 'Receive notifications for good and perfect matches',
  },
  {
    value: '100',
    label: 'Perfect Match',
    description: 'Receive notifications only for perfect matches',
  },
  {
    value: '-1',
    label: 'Never',
    description: 'You will never receive any notifications',
  },
];
