import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTheme } from '@emotion/react';

import { Chip, Stack, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function SkillChip({ skill, onDelete }) {
  const theme = useTheme();
  const trendValue = skill.trend;

  let arrowIcon = null;
  let color = theme.palette.grey[500];

  const handleDelete = useCallback(() => {
    onDelete(skill._id);
  }, [onDelete, skill._id]);

  switch (true) {
    case trendValue >= 100:
      color = theme.palette.success.main;
      arrowIcon = 'solar:double-alt-arrow-up-bold';
      break;

    case trendValue >= 50:
      color = theme.palette.success.main;
      arrowIcon = 'solar:alt-arrow-up-bold';
      break;

    case trendValue > 0:
      color = theme.palette.success.main;
      arrowIcon = 'solar:alt-arrow-up-linear';
      break;

    case trendValue === 0:
      color = theme.palette.grey[500];
      arrowIcon = 'solar:alt-arrow-right-linear';
      break;

    case trendValue < -100:
      color = theme.palette.error.main;
      arrowIcon = 'solar:double-alt-arrow-down-bold';
      break;

    case trendValue < -50:
      color = theme.palette.error.main;
      arrowIcon = 'solar:alt-arrow-down-bold';
      break;

    case trendValue < 0:
      color = theme.palette.warning.main;
      arrowIcon = 'solar:alt-arrow-down-linear';
      break;

    default:
      color = theme.palette.grey[500];
      arrowIcon = 'solar:alt-arrow-right-linear';
      break;
  }

  return (
    <Chip
      key={skill.label}
      label={
        <Stack direction="row" alignItems="center" sx={{ py: 2, width: 1 }}>
          <Typography variant="body2">{skill.label}</Typography>

          <Iconify icon={arrowIcon} color={color} width={18} sx={{ ml: 1.5 }} />

          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {trendValue > 0 ? '+' : ''}
            {trendValue}%
          </Typography>
        </Stack>
      }
      variant="soft"
      onDelete={handleDelete}
      deleteIcon={<Iconify icon="eva:minus-outline" width={18} height={18} />}
    />
  );
}

SkillChip.propTypes = {
  skill: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};
