import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useApi } from 'src/hooks/use-api';

import { fToNow } from 'src/utils/format-time';

import MatchLevel from 'src/components/match-level';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, mutate, closeDrawer }) {
  const api = useApi();

  const renderMatchLevel = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <MatchLevel
          hideLabel
          status="FINISHED"
          robertLevel={notification.data.matchLevel}
          level={notification.data.matchLevel}
          opinion={
            notification.data.opinionLevel
              ? { comment: '', level: notification.data.opinionLevel }
              : null
          }
        />
      </Stack>
    </ListItemAvatar>
  );

  const renderNotificationType = (type) => {
    switch (type) {
      case 'NEW_PROJECT_FOUND':
        return 'New posting found';

      case 'DUPLICATE_PROJECT_FOUND':
        return 'Repeated posting found';

      case 'PROJECT_IS_NOW_MATCHING':
        return 'Recent project is now a match';

      default:
        return null;
    }
  };

  const renderText = (
    <ListItemText
      primary={notification.data.postingTitle}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {renderNotificationType(notification.type)}
          {fToNow(notification.createdAt)}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = notification.status === 'UNREAD' && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
        ml: 5,
      }}
    />
  );

  const markItemAsRead = useCallback(
    async (id) => {
      try {
        await api.patch(`/v1/inbox/${id}`, {
          status: 'READ',
        });

        mutate();
      } catch (error) {
        console.error(error);
      }
    },
    [api, mutate]
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        position: 'relative',
      }}
    >
      {renderUnReadBadge}
      {renderMatchLevel}
      <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>

      <Link
        component={RouterLink}
        href={paths.dashboard.management.group(
          notification.data.projectGroupId,
          undefined,
          'info',
          notification.data.postingId
        )}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
        }}
        onClick={() => {
          markItemAsRead(notification._id);
          closeDrawer();
        }}
      />
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  mutate: PropTypes.func,
  closeDrawer: PropTypes.func,
  notification: PropTypes.shape({
    _id: PropTypes.string,
    userId: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.oneOf(['UNREAD', 'READ']),
    type: PropTypes.oneOf([
      'NEW_PROJECT_FOUND',
      'DUPLICATE_PROJECT_FOUND',
      'PROJECT_IS_NOW_MATCHING',
    ]),
    data: PropTypes.shape({
      projectGroupId: PropTypes.string,
      projectGroupTitle: PropTypes.string,
      postingId: PropTypes.string,
      postingTitle: PropTypes.string,
      matchLevel: PropTypes.number,
      opinionLevel: PropTypes.number,
    }),
  }),
};
