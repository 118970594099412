import { Card, Link, Stack, Tooltip, Typography } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import Image from 'src/components/image';
import RoboHeadline from 'src/components/robo-headline';

// ----------------------------------------------------------------------

export default function GroupApplicationRoboIntroStarted() {
  return (
    <Card sx={{ p: 2 }}>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Link component={RouterLink} href="/robert">
            <Tooltip title="Hi, I'm Robert – nice to meet you!" placement="top" arrow>
              <Image src="/assets/illustrations/robert-icon.png" sx={{ width: 64 }} />
            </Tooltip>
          </Link>
          <RoboHeadline variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            Let&apos;s Get Started!
          </RoboHeadline>
        </Stack>

        <Typography variant="body2" sx={{ p: 0 }}>
          To create a personalized cover letter for you, I need to see your current resume. You can
          upload it anytime through the sidebar. Once I have your resume, I&apos;ll be able to
          generate the perfect cover letter for your project applications.
        </Typography>
      </Stack>
    </Card>
  );
}

GroupApplicationRoboIntroStarted.propTypes = {};
