import useSWR from 'swr';
import { useSnackbar } from 'notistack';

import { swrConfig } from 'src/hooks/swr.config';

import { fetcher } from 'src/utils/api-fetcher';

import { useAuthContext } from 'src/auth/hooks';

export function useInbox(userId, status, page, pageSize, sidebarVisible) {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuthContext();

  let params = [
    `/v1/inbox`,
    getAccessTokenSilently,
    enqueueSnackbar,
    {
      params: {
        ...(typeof page !== 'undefined' && typeof pageSize !== 'undefined'
          ? { from: page * pageSize, limit: pageSize }
          : {}),
        ...(status ? { status } : {}),
        sidebarVisible,
        userId,
      },
    },
  ];

  if (!userId) {
    params = null;
  }

  const { data, error, isLoading, mutate } = useSWR(params, fetcher, swrConfig);

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}
