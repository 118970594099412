import { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';

import GroupInfoPostings from 'src/pages/dashboard/management/group/info/postings';
import GroupAdminActions from 'src/pages/dashboard/management/group/admin-actions';
import GroupInfoPositions from 'src/pages/dashboard/management/group/info/positions';
import GroupInfoMatchInfo from 'src/pages/dashboard/management/group/info/match-info';
import GroupInfoDuplicatesInfo from 'src/pages/dashboard/management/group/info/duplicates-info';

// ----------------------------------------------------------------------

export default function GroupInfoContent({ item, user }) {
  const [selectedPostings, setSelectedPostings] = useState([]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={4} order={{ md: 1 }}>
          <Stack spacing={3}>
            <GroupInfoMatchInfo item={item} user={user} />
            <GroupInfoPositions item={item} user={user} />
          </Stack>
        </Grid>

        <Grid xs={12} md={8} order={{ md: 0 }}>
          <Stack spacing={3}>
            <GroupInfoDuplicatesInfo item={item} user={user} />
            <GroupInfoPostings
              item={item}
              user={user}
              selectedPostings={selectedPostings}
              setSelectedPostings={setSelectedPostings}
            />
          </Stack>
        </Grid>
      </Grid>

      <GroupAdminActions
        item={item}
        user={user}
        selectedPostings={selectedPostings}
        setSelectedPostings={setSelectedPostings}
      />
    </>
  );
}

GroupInfoContent.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    postingCount: PropTypes.number,
    postings: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.string,
        postedAt: PropTypes.string,
        platform: PropTypes.string,
        sourceLink: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        details: PropTypes.shape({
          descriptionLanguage: PropTypes.string,
          authorCompany: PropTypes.string,
          authorName: PropTypes.string,
          hourlyRate: PropTypes.number,
          startDate: PropTypes.string,
          workload: PropTypes.string,
          durationInMonths: PropTypes.number,
          industry: PropTypes.string,
          contractType: PropTypes.string,
          workplace: PropTypes.string,
          location: PropTypes.string,
        }),
      })
    ),
    details: PropTypes.shape({
      hourlyRate: PropTypes.number,
      startDate: PropTypes.string,
      workload: PropTypes.string,
      durationInMonths: PropTypes.number,
      industry: PropTypes.string,
      contractType: PropTypes.string,
      workplace: PropTypes.string,
      location: PropTypes.string,
    }),
    skills: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      references: PropTypes.arrayOf(PropTypes.string),
    }),
    userDetails: PropTypes.shape({
      opinion: PropTypes.shape({
        projectId: PropTypes.string,
        comment: PropTypes.string,
        level: PropTypes.number,
        createdAt: PropTypes.string,
      }),
      matching: PropTypes.shape({
        projectId: PropTypes.string,
        createdAt: PropTypes.string,
        status: PropTypes.string,
        result: PropTypes.shape({
          information: PropTypes.string,
          level: PropTypes.number,
          missingSkills: PropTypes.arrayOf(PropTypes.string),
          missingSkillsReferences: PropTypes.arrayOf(PropTypes.string),
          personalAnalysis: PropTypes.string,
        }),
      }),
      applicationCoverLetters: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          projectId: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
    }),
  }),
  user: PropTypes.object,
};
