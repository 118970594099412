import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useCallback } from 'react';

import {
  Box,
  Stack,
  useTheme,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';

import { useApi } from 'src/hooks/use-api';
import { useBoolean } from 'src/hooks/use-boolean';

import { emphasizeMatchingText } from 'src/utils/emphasize-matching-text';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function AutocompleteSkills({ disabled, label, hideOptions, onSelect }) {
  const theme = useTheme();
  const ref = useRef(null);
  const open = useBoolean(false);
  const dataLoading = useBoolean(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [optionsLoadedForTerm, setOptionsLoadedForTerm] = useState('');
  const api = useApi();

  const formatSignificantSkills = (skills) => {
    const mostSignificant = skills.reduce((acc, skill) => {
      if (skill.significantSkill && !acc.find((s) => s._id === skill.significantSkill._id)) {
        acc.push({
          ...skill.significantSkill,
          type: 'significant',
        });
      }

      return acc;
    }, []);

    return [
      ...mostSignificant,
      ...skills.filter((skill) => !mostSignificant.some((s) => s._id === skill._id)),
    ];
  };

  const fetchOptions = async (value) => {
    if (value.length < 1) {
      setOptions([]);
      return;
    }

    dataLoading.onTrue();

    try {
      const response = await api.get('/v1/skills', {
        params: { startsWith: value, from: 0, limit: 8 },
      });

      const loadedOptions = response.data.data;
      const enrichedOptions = formatSignificantSkills(loadedOptions);

      setOptionsLoadedForTerm(value);
      setOptions(enrichedOptions);
    } catch (error) {
      console.error(error);
    } finally {
      dataLoading.onFalse();
    }
  };

  useEffect(() => {
    open.setValue(searchTerm?.length >= 1);

    if (searchTerm?.length < 1) {
      setOptions([]);
      return () => {};
    }

    // debounce for 250mx and execute the callback
    const debounce = setTimeout(() => {
      fetchOptions(searchTerm);
    }, 250);

    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleChangeEvent = useCallback(
    (_, value, reason) => {
      if (reason === 'selectOption') {
        onSelect(value);
        setSearchTerm('');
        open.onFalse();
      }
    },
    [onSelect, open]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const pickedOption = options.find(
          (option) => option.label.toLowerCase().trim() === searchTerm.toLowerCase().trim()
        );

        if (pickedOption) {
          onSelect(pickedOption);
          setSearchTerm('');
          open.onFalse();
        }
      }
    },
    [onSelect, open, options, searchTerm]
  );

  return (
    <Autocomplete
      ref={ref}
      freeSolo
      disableCloseOnSelect
      disabled={disabled}
      fullWidth
      autoComplete
      clearOnEscape
      clearOnBlur
      // open state
      open={open.value}
      onFocus={open.onTrue}
      onClose={open.onFalse}
      onChange={handleChangeEvent}
      onKeyDown={handleKeyDown}
      inputValue={searchTerm}
      onInputChange={(event, value, reason) => {
        if (reason === 'reset') {
          return;
        }

        setSearchTerm(value);
      }}
      // how to handle options
      isOptionEqualToValue={(option, value) =>
        option?.label?.toLowerCase() === value?.label?.toLowerCase()
      }
      getOptionDisabled={(option) => hideOptions.includes(option._id)}
      filterOptions={(x) => x}
      options={options}
      // loading state
      loading={dataLoading.value}
      noOptionsText={
        optionsLoadedForTerm !== searchTerm || dataLoading.value ? 'Loading...' : 'No options'
      }
      // rendering
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="none"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {dataLoading.value ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={`${searchTerm}-${option._id}`}>
          <Stack direction="row" alignItems="center">
            {options.some((v) => v.type === 'significant') && (
              <Box sx={{ width: '30px', flexShrink: 0 }}>
                {option.type === 'significant' ? (
                  <Iconify icon="solar:star-bold" width="16" color={theme.palette.grey[700]} />
                ) : null}
              </Box>
            )}
            <Stack direction="column">
              <Typography key="1" variant="body2">
                {emphasizeMatchingText(option.label, searchTerm)}
              </Typography>
              <Typography key="2" variant="caption">
                ({option.totalCount} projects, {option.trend > 0 ? '+' : ''}
                {option.trend}% in last 4 weeks)
              </Typography>
            </Stack>
          </Stack>
        </li>
      )}
    />
  );
}

AutocompleteSkills.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hideOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};
